import {
  ShoppingCartCheckout,
  WatchLater,
  MonetizationOn,
  Home,
  Settings,
  SolarPower,
  CurrencyExchange,
  Store,
  SupervisorAccount,
  Groups,
  AccountCircle,
  AccountBalanceWallet,
  PriceChange,
  ReceiptLong,
  LocationCity,
  Assessment,
  Dashboard,
  AcUnit,
} from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import moment from 'moment';
import { ResourceKey } from 'utils';

export const icons = {
  ShoppingCartCheckout,
  WatchLater,
  Dashboard,
  Assessment,
  ReceiptLong,
  MonetizationOn,
  Home,
  Settings,
  SolarPower,
  CurrencyExchange,
  Store,
  SupervisorAccount,
  Groups,
  AccountCircle,
  AccountBalanceWallet,
  PriceChange,
  LocationCity,
  AcUnit,
  SummarizeIcon,
};
export type IconName = keyof typeof icons;
/**
 * 0 is local
 * 1 is development
 * 2 is production
 */
export type NavigationItem = {
  id: string;
  name: string;
  icon?: IconName;
  to?: string;
  children?: NavigationItem[];
  resource?: ResourceKey | '*';
  mode: 0 | 1 | 2;
};

const navigationKeys = {
  tier: 'tier',
  superAdmin: 'superAdmin',
  admin: 'admin',
  // app: 'app',
} as const;
export type NavigationKey =
  (typeof navigationKeys)[keyof typeof navigationKeys];

export type Navigation = {
  [k in NavigationKey]?: {
    title: string;
    items: NavigationItem[];
  };
};
const startedAt = moment().add(-1, 'M').format();
const endedAt = moment().format();
const navigations: Navigation = {
  superAdmin: {
    //ສຳນັກງານໃຫຍ່ => Super admin
    title: "ສຳນັກງານໃຫຍ່",
    items: [
      {
        id: "super-admin-dashboard",
        name: "ພາບລວມ",
        icon: "Dashboard",
        to: "/admin/dashboard",
        resource: "admin-report-inventory",
        mode: 2,
      },
      {
        id: "company",
        name: "ຂໍ້ມູນບໍລິສັດ",
        icon: "LocationCity",
        to: "/super-admin/company",
        resource: "companies",
        mode: 2,
      },
      {
        id: "activity-logging",
        name: "ບັນທຶກກິດຈະກຳການໃຊ້ງານ",
        icon: "WatchLater",
        to: "/super-admin/activities",
        resource: "activities",
        mode: 2,
      },
      {
        id: "super-admin-product-price",
        name: "ອະນຸມັດລາຄາສິນຄ້າ",
        to: "/super-admin/product-prices/pending-approve",
        resource: "admin-purchasePrices",
        icon: "MonetizationOn",
        mode: 2,
      },
      {
        id: "super-admin-exchange-rates",
        name: "ອັດຕາແລກປ່ຽນ",
        to: "/super-admin/exchange-rates",
        icon: "CurrencyExchange",
        resource: "exchangeRates",
        mode: 2,
      },
      {
        id: "super-admin-users",
        name: "ຂໍ້ມູນຜູ້ໃຊ້",
        to: "/super-admin/users",
        icon: "AccountCircle",
        resource: "admin-users",
        mode: 2,
      },
      {
        id: "super-admin-customers",
        name: "ຂໍ້ມູນລູກຄ້າ",
        to: "/super-admin/customers",
        resource: "admin-customers",
        icon: "Groups",
        mode: 2,
      },
      {
        id: "super-admin-expense-types",
        name: "ຂໍ້ມູນປະເພດລາຍຈ່າຍ",
        to: "/super-admin/expense-types",
        resource: "expenseTypes",
        icon: "ReceiptLong",
        mode: 2,
      },
      {
        id: "super-admin-setting",
        name: "ຕັ້ງຄ່າ",
        icon: "Settings",
        mode: 2,
        children: [
          {
            id: "super-admin-zones",
            name: "ຈັດການຂໍ້ມູນໂຊນ",
            to: "/super-admin/zones",
            resource: "admin-zones",
            mode: 2,
          },
          {
            id: "super-admin-tiers",
            name: "ຈັດການຂໍ້ມູນລານ",
            to: "/super-admin/tiers",
            resource: "admin-tiers",
            mode: 2,
          },
          {
            id: "super-admin-wallets",
            name: "ຈັດການຂໍ້ມູນກະເປົາເງິນ",
            to: "/super-admin/wallets",
            resource: "admin-wallets",
            mode: 2,
          },
          {
            id: "super-admin-seasons",
            name: "ຈັດການຂໍ້ມູນລະດູການ",
            to: "/super-admin/seasons",
            resource: "seasons",
            mode: 2,
          },
          {
            id: "super-admin-categories",
            name: "ຂໍ້ມູນປະເພດສິນຄ້າ",
            to: "/super-admin/categories",
            resource: "admin-products",
            mode: 2,
          },
          {
            id: "super-admin-products",
            name: "ຂໍ້ມູນສິນຄ້າ",
            to: "/super-admin/products",
            resource: "admin-products",
            mode: 2,
          },
          {
            id: "super-admin-roles",
            name: "ສິດທິ ແລະ ການເຂົ້າເຖິງ",
            to: "/super-admin/roles",
            resource: "admin-roles",
            mode: 2,
          },
        ],
      },
      {
        id: "super-admin-report-purchases",
        name: "ລາຍງານການຊື້",
        icon: "ShoppingCartCheckout",
        mode: 2,
        children: [
          {
            id: "super-admin-report-purchases-summaries",
            name: "ລາຍງານປະລິມານການຊື້",
            to: `/super-admin/report/purchases-summaries?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-purchase",
            mode: 2,
          },
          {
            id: "super-admin-purchases-moisture",
            name: "ລາຍງານຕົ້ນທຶນຕາມຄວາມຊຸ່ມ",
            to: `/admin/report/purchases-moisture?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-purchase",
            mode: 2,
          },
          {
            id: "admin-report-purchases-estimate-loss",
            name: "ລາຍງານຄາດຄະເນການສູນເສຍ",
            to: "/admin/report/purchases-estimate-loss",
            resource: "admin-report-purchase",
            mode: 2,
          },
          {
            id: "admin-report-purchases-payment-method",
            name: "ແຍກລາຍງານການຮັບຊື້ຈາກລານລູກ ແລະ ລູກຄ້າ",
            to: "/admin/report/purchases-paymentMethod",
            resource: "admin-report-purchase",
            mode: 2,
          },
          // {
          //   id: "admin-report-purchases-customers",
          //   name: "ລາຍການຊື້ຕາມລູກຄ້າ",
          //   to: "/admin/report/purchases-customers",
          //   resource: "admin-report-purchase",
          //   mode: 2,
          // },
        ],
      },
      {
        id: "admin-report-inventories",
        name: "ລາຍງານການແປຮູບສິນຄ້າ",
        icon: "SolarPower",
        mode: 2,
        children: [
          {
            id: "admin-report-lot-summary-weight",
            name: "ລາຍງານສິນຄ້າໃນສາງ (Stocks) ",
            to: `/admin/report/lot-summary-weight?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-inventory",
            mode: 2,
          },
          {
            id: "admin-report-lots-loss",
            name: "ລາຍງານເປີເຊັນຄ່າສູນເສຍແຕ່ລະລ໋ອດ",
            to: `/admin/report/lots-loss?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-inventory",
            mode: 2,
          },
        ],
      },
      {
        id: "super-admin-report-sales",
        name: "ລາຍງານການຂາຍ",
        icon: "Store",
        mode: 2,
        children: [
          {
            id: "super-admin-sales",
            name: "ລາຍງານປະລິມານການຂາຍ",
            to: `/admin/report/sale-summaries?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "admin-report-invoice-summaries",
            name: "ລາຍງານການອອກໃບຮຽກເກັບເງິນ",
            to: `/admin/report/invoice-summaries?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "admin-receipts",
            name: "ລາຍງານການອອກໃບຮັບເງິນ",
            to: `/admin/report/receipt-summaries?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "admin-report-sale-customers",
            name: "ລາຍງານການຂາຍຕາມລູກຄ້າ",
            to: `/admin/report/sale-customers?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
        ],
      },
      {
        id: "admin-report-profit-loss",
        name: "ລາຍງານກຳໄລ/ຂາດທືນ",
        icon: "SummarizeIcon",
        mode: 2,
        children: [
          {
            id: "admin-report-profit-loss-zones",
            name: "ລວມທັງບໍລິສັດ",
            to: `/admin/report/profit-zones?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "admin-report-profit-loss-tier",
            name: "ລານ",
            to: `/admin/report/profit-tiers?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "admin-report-profit-loss-tier",
            name: "ລາຍງານກຳໄລຂາດທຶນຕາມລາຍຊື່ລູກຄ້າ/ຕາມປາຍທາງ",
            to: `/admin/report/profit-customers?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
        ],
      },
      {
        id: "admin-report-wallets-zones",
        name: "ລາຍງານກະເປົາເງິນ",
        icon: "SummarizeIcon",
        to: `/admin/report/wallet-zones?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
      {
        id: "admin-report-expenses-types",
        name: "ລາຍງານຕາມປະເພດລາຍຈ່າຍ",
        icon: "SummarizeIcon",
        to: `/admin/report/expenses-types?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
      {
        id: "admin-report-rival-purchase-prices",
        name: "ລາຍງານລາຄາມັນລານຄູ່ແຂ່ງ",
        icon: "SummarizeIcon",
        to: `/admin/report/rival-prices?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
    ],
  },
  admin: {
    //ສຳນັກງານ => Admin
    title: "ໂຊນ",
    items: [
      // {
      //   id: 'cassava-price-today-admin',
      //   name: 'ອະນຸມັດລາຄາສິນຄ້າ',
      //   icon: 'MonetizationOn',
      //   to: '/admin/product-prices',
      //   resource: 'zone-purchasePrices',
      //   mode: 2,
      // },
      {
        id: "zone-dashboard",
        name: "dashboard",
        icon: "Groups",
        to: "/zone/dashboard",
        resource: "customers",
        mode: 2,
      },
      {
        id: "zone-report-purchases",
        name: "ລາຍງານການຊື້",
        icon: "ShoppingCartCheckout",
        mode: 2,
        children: [
          {
            id: "zone-report-purchases-summaries",
            name: "ລາຍງານປະລິມານການຊື້",
            to: `/zone/report/purchases-summaries?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "purchases",
            mode: 2,
          },
          {
            id: "zone-report-purchases-moisture",
            name: "ລາຍງານຕົ້ນທຶນຕາມຄວາມຊຸ່ມ",
            to: `/zone/report/purchases-moisture?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "purchases",
            mode: 2,
          },
          {
            id: "zone-report-purchases-estimate-loss",
            name: "ລາຍງານຄາດຄະເນການສູນເສຍ",
            to: "/zone/report/purchases-estimate-loss",
            resource: "admin-report-purchase",
            mode: 2,
          },
          {
            id: "zone-report-purchases-payment-method",
            name: "ແຍກລາຍງານການຮັບຊື້ຈາກລານລູກ ແລະ ລູກຄ້າ",
            to: "/zone/report/purchases-paymentMethod",
            resource: "admin-report-purchase",
            mode: 2,
          },
        ],
      },
      {
        id: "zone-report-inventories",
        name: "ລາຍງານການແປຮູບສິນຄ້າ",
        icon: "SolarPower",
        mode: 2,
        children: [
          {
            id: "zone-report-lot-summary-weight",
            name: "ລາຍງານສິນຄ້າໃນສາງ (Stocks) ",
            to: `/zone/report/lot-summary-weight?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "inventories",
            mode: 2,
          },
          {
            id: "zone-report-lots-loss",
            name: "ລາຍງານເປີເຊັນຄ່າສູນເສຍແຕ່ລະລ໋ອດ",
            to: `/zone/report/lots-loss?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "inventories",
            mode: 2,
          },
        ],
      },
      {
        id: "zone-report-sales",
        name: "ລາຍງານການຂາຍ",
        icon: "Store",
        mode: 2,
        children: [
          {
            id: "zone-report-sale-summaries",
            name: "ລາຍງານປະລິມານການຂາຍ",
            to: `/zone/report/sale-summaries?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "zone-report-invoice-summaries",
            name: "ລາຍງານການອອກໃບຮຽກເກັບເງິນ",
            to: `/zone/report/invoice-summaries?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "zone-report-receipt-summaries",
            name: "ລາຍງານການອອກໃບຮັບເງິນ",
            to: `/zone/report/receipt-summaries?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "zone-report-sale-customers",
            name: "ລາຍງານການຂາຍຕາມລູກຄ້າ",
            to: `/zone/report/sale-customers?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
        ],
      },
      {
        id: "zone-report-profit-loss",
        name: "ລາຍງານກຳໄລ/ຂາດທືນ",
        icon: "Store",
        mode: 2,
        to: `/zone/report/profit-tiers?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
      },
      {
        id: "zone-report-profit-customers",
        name: "ລາຍງານກຳໄລຂາດທຶນຕາມລາຍຊື່ລູກຄ້າ/ຕາມປາຍທາງ",
        icon: "Store",
        to: `/zone/report/profit-customers?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
      {
        id: "zone-report-expenses-types",
        name: "ລາຍງານຕາມປະເພດລາຍຈ່າຍ",
        icon: "Store",
        to: `/zone/report/expenses-types?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
      {
        id: "zone-report-rival-purchase-prices",
        name: "ລາຍງານລາຄາມັນລານຄູ່ແຂ່ງ",
        icon: "Store",
        to: `/zone/report/rival-prices?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
    ],
  },
  tier: {
    //ລານ => Tier
    title: "ລານ",
    items: [
      {
        id: "tier-menu",
        name: "ໜ້າຫຼັກ",
        icon: "Home",
        to: "/tiers/:tierName/menu",
        resource: "*",
        mode: 2,
      },
      // {
      //   id: 'tier-dashboard',
      //   name: 'ພາບລວມ',
      //   icon: <Dashboard />,
      //   to: '/tiers/:tierName/dashboard',
      // },
      {
        id: "tier-product-price",
        name: "ຕັ້ງລາຄາສິນຄ້າ",
        to: "/tiers/:tierName/moisture-product-price/pending-approve",
        resource: "purchasePrices",
        icon: "MonetizationOn",
        mode: 2,
      },
      {
        id: "tier-rival-purchase-prices",
        name: "ລາຄາມັນລານຄູ່ແຂ່ງ",
        to: "/tiers/:tierName/rival-purchase-prices",
        resource: "rivalPurchasePrices",
        icon: "PriceChange",
        mode: 2,
      },
      {
        id: "tier-wallet",
        name: "ກະເປົາເງິນ",
        to: "/tiers/:tierName/wallets",
        icon: "AccountBalanceWallet",
        resource: "wallets",
        mode: 2,
      },
      {
        id: "expenses",
        name: "ຂໍ້ມູນລາຍຈ່າຍ",
        to: "/tiers/:tierName/expenses",
        resource: "expenses",
        icon: "ReceiptLong",
        mode: 2,
      },
      {
        id: "tier-customers",
        name: "ຂໍ້ມູນລູກຄ້າ",
        icon: "Groups",
        to: "/tiers/:tierName/customers",
        resource: "customers",
        mode: 2,
      },
      {
        id: "tier-purchasing-product",
        name: "ຈັດການຮັບຊື້ສິນຄ້າ",
        icon: "ShoppingCartCheckout",
        mode: 2,
        children: [
          {
            id: "tier-purchasing-purchases",
            name: "ຮັບຊື້ສິນຄ້າ",
            to: `/tiers/:tierName/purchases?startedAt=${encodeURIComponent(
              moment().format()
            )}`,
            resource: "purchases",
            mode: 2,
          },
          {
            id: "tier-PurchasesSummaries",
            name: "ຍອດສັ່ງຊື້ສິນຄ້າ",
            to: "/tiers/:tierName/purchases-summaries",
            resource: "purchases",
            mode: 2,
          },
          {
            id: "tier-audit",
            name: "ລາຍການກວດສອບ",
            to: `/tiers/:tierName/audit?startedAt=${encodeURIComponent(
              moment().format()
            )}`,
            resource: "purchases",
            mode: 2,
          },
        ],
      },
      {
        id: "tier-inventory",
        name: "ຈັດການແປຮູບສິນຄ້າ",
        icon: "SolarPower",
        mode: 2,
        children: [
          {
            id: "tier-stocks",
            name: "ລາຍການປະລິມານການຊື້ມັນສະສົມ",
            to: "/tiers/:tierName/stocks",
            resource: "inventories",
            mode: 2,
          },
          {
            id: "tier-lots",
            name: "ລາຍການລ໋ອດ",
            to: "/tiers/:tierName/lots",
            resource: "inventories",
            mode: 2,
          },
        ],
      },
      {
        id: "tier-sale",
        name: "ຈັດການການຂາຍ",
        icon: "Store",
        mode: 2,
        children: [
          {
            id: "tier-sale-orders",
            name: "ໃບສັ່ງຊື້ (PO)",
            to: `/tiers/:tierName/sale/orders?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: "sales",
            mode: 2,
          },
          {
            id: "ໃບຮຽກເກັບເງິນ (Invoice)",
            name: "ໃບຮຽກເກັບເງິນ (Invoice)",
            to: `/tiers/:tierName/sale/invoices?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: "sales",
            mode: 2,
          },
          {
            id: "tier-wallet",
            name: "ລາຍການການຂາຍ (sync)",
            to: `/tiers/:tierName/sale/transactions?startedAt=${encodeURIComponent(
              endedAt
            )}&orderBy=ASC`,
            resource: "sales",
            mode: 2,
          },
          {
            id: "tier-sale-audit",
            name: "ລາຍການກວດສອບ",
            to: `/tiers/:tierName/sale-audit?startedAt=${encodeURIComponent(
              moment().format()
            )}`,
            resource: "sales",
            mode: 2,
          },
          {
            id: "ໃບໂອນ (Transfer slip)",
            name: "ໃບໂອນ (Transfer slip)",
            to: `/tiers/:tierName/sale/transfers?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: "sales",
            mode: 2,
          },
          {
            id: "ໃບຮັບເງິນ (Receipt)",
            name: "ໃບຮັບເງິນ (Receipt)",
            to: `/tiers/:tierName/sale/receipts?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}&orderBy=ASC`,
            resource: "sales",
            mode: 2,
          },
        ],
      },
      {
        id: "tier-dashboard",
        name: "dashboard",
        icon: "Groups",
        to: "/tiers/:tierName/dashboard",
        resource: "customers",
        mode: 2,
      },
      {
        id: "tier-report-purchases",
        name: "ລາຍງານການຊື້",
        icon: "SummarizeIcon",
        mode: 2,
        children: [
          {
            id: "tier-report-purchases-summaries",
            name: "ລາຍງານປະລິມານການຊື້",
            to: `/tiers/:tierName/report/purchases-summaries?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "purchases",
            mode: 2,
          },
          {
            id: "tier-report-purchases-moisture",
            name: "ລາຍງານຕົ້ນທຶນຕາມຄວາມຊຸ່ມ",
            to: `/tiers/:tierName/report/purchases-moisture?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "purchases",
            mode: 2,
          },
          {
            id: "tier-report-purchases-estimate-loss",
            name: "ລາຍງານຄາດຄະເນການສູນເສຍ",
            to: `/tiers/:tierName/report/purchases-estimate-loss?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-purchase",
            mode: 2,
          },
          {
            id: "admin-report-purchases-payment-method",
            name: "ແຍກລາຍງານການຮັບຊື້ຈາກລານລູກ ແລະ ລູກຄ້າ",
            to: "/tiers/:tierName/report/purchases-paymentMethod",
            resource: "admin-report-purchase",
            mode: 2,
          },
        ],
      },
      {
        id: "tier-report-inventories",
        name: "ລາຍງານການແປຮູບສິນຄ້າ",
        icon: "SummarizeIcon",
        mode: 2,
        children: [
          {
            id: "tier-report-lot-summary-weight",
            name: "ລາຍງານສິນຄ້າໃນສາງ (Stocks) ",
            to: `/tiers/:tierName/report/lot-summary-weight?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "inventories",
            mode: 2,
          },
          {
            id: "tier-report-lots-loss",
            name: "ລາຍງານເປີເຊັນຄ່າສູນເສຍແຕ່ລະລ໋ອດ",
            to: `/tiers/:tierName/report/lots-loss?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "inventories",
            mode: 2,
          },
        ],
      },
      {
        id: "tier-report-sales",
        name: "ລາຍງານການຂາຍ",
        icon: "SummarizeIcon",
        mode: 2,
        children: [
          {
            id: "tier-report-sale-summaries",
            name: "ລາຍງານປະລິມານການຂາຍ",
            to: `/tiers/:tierName/report/sale-summaries?timeline=MONTH&keyName=weight&startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "tier-report-invoice-summaries",
            name: "ລາຍງານການອອກໃບຮຽກເກັບເງິນ",
            to: `/tiers/:tierName/report/invoice-summaries?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "tier-report-receipt-summaries",
            name: "ລາຍງານການອອກໃບຮັບເງິນ",
            to: `/tiers/:tierName/report/receipt-summaries?endedAt=${encodeURIComponent(
              endedAt
            )}&startedAt=${encodeURIComponent(startedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
          {
            id: "tier-report-sale-customers",
            name: "ລາຍງານການຂາຍຕາມລູກຄ້າ",
            to: `/tiers/:tierName/report/sale-customers?startedAt=${encodeURIComponent(
              startedAt
            )}&endedAt=${encodeURIComponent(endedAt)}`,
            resource: "admin-report-sale",
            mode: 2,
          },
        ],
      },
      {
        id: "tiers-report-expenses-types",
        name: "ລາຍງານຕາມປະເພດລາຍຈ່າຍ",
        icon: "Store",
        to: `/tiers/:tierName/report/expenses-types?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
      {
        id: "tiers-report-profit-customers",
        name: "ລາຍງານກຳໄລຂາດທຶນຕາມລາຍຊື່ລູກຄ້າ/ຕາມປາຍທາງ",
        icon: "Store",
        to: `/tiers/:tierName/report/profit-customers?startedAt=${encodeURIComponent(
          startedAt
        )}&endedAt=${encodeURIComponent(endedAt)}`,
        resource: "admin-report-sale",
        mode: 2,
      },
    ],
  },
};
export default navigations;
