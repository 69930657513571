import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { moistureGroup } from 'models/purchase.report.v2';
import type { SaleSummary } from 'models/sale.report.v2';
import type { TierIncome } from 'models/profit-loss';
import type { QueryParams } from 'models/dashboard.v2';

export interface InitialState {
  moistureGroup: {
    isLoading: boolean;
    entries: {
      [key: string]: moistureGroup;
    };
  };
  income: {
    isLoading: boolean;
    entries: TierIncome[];
  };
  salePrice: {
    isLoading: boolean;
    entries: SaleSummary[];
  };
}
const initialState: InitialState = {
  moistureGroup: {
    isLoading: false,
    entries: {},
  },
  income: {
    entries: [],
    isLoading: false,
  },
  salePrice: {
    entries: [],
    isLoading: false,
  },
};
export enum Types {
  listMoistureGroupRequest = 'dashboard/listMoistureGroupRequest',
  listMoistureGroupSuccess = 'dashboard/listMoistureGroupSuccess',
  listMoistureGroupFailure = 'dashboard/listMoistureGroupFailure',
  incomeRequest = 'dashboard/incomeRequest',
  incomeSuccess = 'dashboard/incomeSuccess',
  incomeFailure = 'dashboard/incomeFailure',
  salePriceRequest = 'dashboard/salePriceRequest',
  salePriceSuccess = 'dashboard/salePriceSuccess',
  salePriceFailure = 'dashboard/salePriceFailure',
}
const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    listMoistureGroupRequest: (state, _: PayloadAction<QueryParams>) => {
      state.moistureGroup.isLoading = true;
    },
    listMoistureGroupSuccess: (
      state,
      action: PayloadAction<{
        entries: {
          [key: string]: moistureGroup;
        };
      }>
    ) => {
      state.moistureGroup.entries = action.payload.entries;
      state.moistureGroup.isLoading = false;
    },
    listMoistureGroupFailure: (state) => {
      state.moistureGroup.isLoading = false;
    },
    incomeRequest: (state, _: PayloadAction<QueryParams>) => {
      state.income.isLoading = true;
    },
    incomeSuccess: (
      state,
      action: PayloadAction<{
        entries: TierIncome[];
      }>
    ) => {
      state.income.entries = action.payload.entries;
      state.income.isLoading = false;
    },
    incomeFailure: (state) => {
      state.income.isLoading = false;
    },
    salePriceRequest: (state, _: PayloadAction<QueryParams>) => {
      state.salePrice.isLoading = true;
    },
    salePriceSuccess: (
      state,
      action: PayloadAction<{
        entries: SaleSummary[];
      }>
    ) => {
      state.salePrice.entries = action.payload.entries;
      state.salePrice.isLoading = false;
    },
    salePriceFailure: (state) => {
      state.salePrice.isLoading = false;
    },
  },
});
export const {
  listMoistureGroupFailure,
  listMoistureGroupRequest,
  listMoistureGroupSuccess,
  incomeFailure,
  incomeRequest,
  incomeSuccess,
  salePriceFailure,
  salePriceRequest,
  salePriceSuccess,
} = slice.actions;
export default slice.reducer;
