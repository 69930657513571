import { lazy } from 'react';

export const Sales = lazy(() => import('./sales/Sales'));
export const SalesTransactions = lazy(
  () => import('./sales/SalesTransactions')
);
export const SummaryStocks = lazy(() => import('./inventories/SummaryStocks'));
export const SummaryLots = lazy(() => import('./inventories/SummaryLots'));
export const EntitiesLots = lazy(() => import('./inventories/Lots'));
export const SalesInvoices = lazy(() => import('./sales/invoices'));
export const SummaryInvoices = lazy(() => import('./sales/SummaryInvoices'));
export const SalesReceipts = lazy(() => import('./sales/receipts'));
export const SummaryReceipts = lazy(() => import('./sales/SummaryReceipts'));
export const SummaryExpenses = lazy(() => import('./expenses/SummaryExpenses'));
export const Expenses = lazy(() => import('./expenses/Expenses'));
export const WalletsAccounts = lazy(() => import('./wallets/Accounts'));
export const WalletsTransactions = lazy(() => import('./wallets/Transactions'));
