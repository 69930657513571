import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  QueryParams,
  Expense,
  TierCost,
  TierIncome,
  Summary,
  ProfitLossCustomer,
  CustomerTx
} from 'models/profit-loss';

export interface InitialState {
  expense: Expense[];
  cost: TierCost[];
  income: TierIncome[];
  summary: {
    expense: Summary;
    cost: Summary;
    income: Summary;
  };
  isLoading: boolean;
  customers:{
    entities: ProfitLossCustomer[];
    isLoading: boolean;
  }
  customerTxs: {
    entities: CustomerTx[];
    isLoading: boolean;
  }
}
const initialState: InitialState = {
  isLoading: false,
  expense: [],
  cost: [],
  income: [],
  summary: {
    expense: {
      amount: 0,
      price: 0,
      volumnKg: 0,
    },
    cost: {
      amount: 0,
      price: 0,
      volumnKg: 0,
    },
    income: {
      amount: 0,
      price: 0,
      volumnKg: 0,
    },
  },
  customers: {
    entities: [],
    isLoading: false
  },
  customerTxs: {
    entities: [],
    isLoading: false
  }
};
export enum Types {
  listProfitLossRequest = 'report/listProfitLossRequest',
  listProfitLossSuccess = 'report/listProfitLossSuccess',
  listProfitLossFailure = 'report/listProfitLossFailure',
  profitLossCustomerRequest = 'report/profitLossCustomerRequest',
  profitLossCustomerSuccess = 'report/profitLossCustomerSuccess',
  profitLossCustomerFailure = 'report/profitLossCustomerFailure',
  profitLossCustomerTxRequest = 'report/profitLossCustomerTxRequest',
  profitLossCustomerTxSuccess = 'report/profitLossCustomerTxSuccess',
  profitLossCustomerTxFailure = 'report/profitLossCustomerTxFailure',
}
const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    listProfitLossRequest: (state, _: PayloadAction<QueryParams>) => {
      state.isLoading = true;
    },
    listProfitLossSuccess: (
      state,
      action: PayloadAction<{
        expense: Expense[];
        cost: TierCost[];
        income: TierIncome[];
        summary: {
          expense: Summary;
          cost: Summary;
          income: Summary;
        };
        isLoading: boolean;
      }>
    ) => {
      state.isLoading = false;
      state.expense = action.payload.expense;
      state.cost = action.payload.cost;
      state.income = action.payload.income;
      state.summary = action.payload.summary;
    },
    listProfitLossFailure: (state) => {
      state.isLoading = false;
    },
    profitLossCustomerRequest: (state, _: PayloadAction<QueryParams>) => {
      state.customers.isLoading = true;
    },
    profitLossCustomerSuccess: (state, action: PayloadAction<{
      entities: ProfitLossCustomer[]
    }>) => {
      state.customers.isLoading = false;
      state.customers.entities = action.payload.entities;
    },
    profitLossCustomerFailure: (state) => {
      state.customers.isLoading = false;
    },
    profitLossCustomerTxRequest: (state, _: PayloadAction<QueryParams>) => {
      state.customerTxs.isLoading = true;
    },
    profitLossCustomerTxSuccess: (
      state,
      action: PayloadAction<{
        entities: CustomerTx[]
      }>
    ) => {
      state.customerTxs.isLoading = false;
      state.customerTxs.entities = action.payload.entities;
    },
    profitLossCustomerTxFailure: (state) => {
      state.customerTxs.isLoading = false;
    },
  },
});
export const {
  listProfitLossFailure,
  listProfitLossRequest,
  listProfitLossSuccess,
  profitLossCustomerFailure,
  profitLossCustomerRequest,
  profitLossCustomerSuccess,
  profitLossCustomerTxFailure,
  profitLossCustomerTxRequest,
  profitLossCustomerTxSuccess
} = slice.actions;
export default slice.reducer;
