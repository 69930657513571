import { all, fork } from 'redux-saga/effects';
import purchases from './purchases/saga';
import inventory from './inventory/saga';
import sale from './sale/saga';
import dashboard from './dashboard/saga';
import profit from './profit/saga';
import wallet from './wallet/saga';
import expenses from './expenses/saga';

export default function* sagas() {
  yield all([
    fork(purchases),
    fork(inventory),
    fork(sale),
    fork(dashboard),
    fork(profit),
    fork(wallet),
    fork(expenses),
  ]);
}
