import { combineReducers } from 'redux';
import purchases from './purchases';
import inventory from './inventory';
import sale from './sale';
import dashboard from './dashboard';
import profit from './profit';
import wallet from './wallet';
import expenses from './expenses';

export default combineReducers({
  purchases,
  inventory,
  sale,
  dashboard,
  profit,
  wallet,
  expenses,
});
