import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  QueryParams,
  ExpenseTypeSummary,
  ExpenseTransaction,
  ExpenseTypeGroupByTier,
} from 'models/expense.report.v2';

export interface InitialState {
  expensesTypes: {
    isLoading: boolean;
    entries: ExpenseTypeSummary[];
  };
  zones: {
    isLoading: boolean;
    entries: ExpenseTypeGroupByTier[];
    result?: ExpenseTypeSummary;
  };
  tiers: {
    isLoading: boolean;
    entries: ExpenseTypeGroupByTier[];
    result?: ExpenseTypeGroupByTier;
  };
  transactions: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    lastPageToken: string;
    result?: ExpenseTypeGroupByTier;
    pages: {
      [key: number]: {
        entries: ExpenseTransaction[];
        pageToken: string;
      };
    };
  };
}
const initialState: InitialState = {
  expensesTypes: {
    entries: [],
    isLoading: false,
  },
  zones: {
    entries: [],
    isLoading: false,
  },
  tiers: {
    entries: [],
    isLoading: false,
  },
  transactions: {
    isExporting: false,
    isLoading: false,
    lastPageToken: '',
    rowCount: 0,
    pages: {},
  },
};
export enum Types {
  listExpensesTypesRequest = 'report/listExpensesTypesRequest',
  listExpensesTypesSuccess = 'report/listExpensesTypesSuccess',
  listExpensesTypesFailure = 'report/listExpensesTypesFailure',
  listGroupByZoneRequest = 'report/listGroupByZoneRequest',
  listGroupByZoneSuccess = 'report/listGroupByZoneSuccess',
  listGroupByZoneFailure = 'report/listGroupByZoneFailure',
  listGroupByTierRequest = 'report/listGroupByTierRequest',
  listGroupByTierSuccess = 'report/listGroupByTierSuccess',
  listGroupByTierFailure = 'report/listGroupByTierFailure',
  listExTypesTxsRequest = 'report/listExTypesTxsRequest',
  listExTypesTxsSuccess = 'report/listExTypesTxsSuccess',
  listExTypesTxsFailure = 'report/listExTypesTxsFailure',
}
const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    listExpensesTypesRequest: (state, _: PayloadAction<QueryParams>) => {
      state.expensesTypes.isLoading = true;
    },
    listExpensesTypesSuccess: (
      state,
      action: PayloadAction<ExpenseTypeSummary[]>
    ) => {
      state.expensesTypes.entries = action.payload;
      state.expensesTypes.isLoading = false;
    },
    listExpensesTypesFailure(state) {
      state.expensesTypes.isLoading = false;
    },
    listGroupByZoneRequest: (state, _: PayloadAction<QueryParams>) => {
      state.zones.isLoading = true;
    },
    listGroupByZoneSuccess: (
      state,
      action: PayloadAction<{
        entries: ExpenseTypeGroupByTier[];
        result?: ExpenseTypeSummary;
      }>
    ) => {
      state.zones.entries = action.payload.entries;
      if (action.payload.result) state.zones.result = action.payload.result;
      state.zones.isLoading = false;
    },
    listGroupByZoneFailure: (state) => {
      state.zones.isLoading = false;
    },
    listGroupByTierRequest: (state, _: PayloadAction<QueryParams>) => {
      state.tiers.isLoading = true;
    },
    listGroupByTierSuccess: (
      state,
      action: PayloadAction<{
        entries: ExpenseTypeGroupByTier[];
        result?: ExpenseTypeGroupByTier;
      }>
    ) => {
      state.tiers.entries = action.payload.entries;
      if (action.payload.result) state.tiers.result = action.payload.result;
      state.tiers.isLoading = false;
    },
    listGroupByTierFailure: (state) => {
      state.tiers.isLoading = false;
    },
    listExTypesTxsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.transactions.isLoading = true;
    },
    listExTypesTxsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: ExpenseTransaction[];
        result: ExpenseTypeGroupByTier;
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.transactions.isLoading = false;
      state.transactions.result = payload.result;
      state.transactions.isLoading = false;
      state.transactions.pages = {
        ...state.transactions.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.transactions.lastPageToken = payload.pageToken;
    },
    listExTypesTxsFailure: (state) => {
      state.transactions.isLoading = false;
    },
  },
});
export const {
  listExpensesTypesRequest,
  listExpensesTypesSuccess,
  listExpensesTypesFailure,
  listGroupByZoneRequest,
  listGroupByZoneSuccess,
  listGroupByZoneFailure,
  listGroupByTierRequest,
  listGroupByTierSuccess,
  listGroupByTierFailure,
  listExTypesTxsFailure,
  listExTypesTxsRequest,
  listExTypesTxsSuccess,
} = slice.actions;
export default slice.reducer;
