import axios from 'axios';
// import mockUpData from './mock-data';

// import { mappingErrorCode } from 'utils';
import { LoginResponse } from 'models';
const publicInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
});
// if (process.env.REACT_APP_IS_MOCK_UP) {
//   mockUpData(publicInstance);
// }
export default publicInstance;

export const refreshToken = async (): Promise<string | null> => {
  try {
    const { data }: LoginResponse = await publicInstance.post(
      '/v1/auth/login-refresh',
      {
        refreshToken: localStorage.getItem('refreshToken') || '',
      }
    );
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    return data.accessToken;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return null;
  }
};
