import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
import type { moistureGroup } from 'models/purchase.report.v2';
import type { SaleSummary } from 'models/sale.report.v2';
import type { TierIncome } from 'models/profit-loss';
function* listMoistureGroup({
  payload,
}: ReturnType<typeof actions.listMoistureGroupRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data } = (yield call(
      API.get,
      `/v1/dashboard/purchase-by-moisture`,
      {
        params,
      }
    )) as AxiosResponse<{
      [key: string]: moistureGroup;
    }>;
    yield put(
      actions.listMoistureGroupSuccess({
        entries: data,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listMoistureGroupFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchListMoistureGroup() {
  yield takeLatest(actions.Types.listMoistureGroupRequest, listMoistureGroup);
}
function* watchIncome() {
  yield takeLatest(
    actions.Types.incomeRequest,
    function* (action: ReturnType<typeof actions.incomeRequest>) {
      const { payload } = action;
      const params = {
        ...payload,
      };
      try {
        const { data } = (yield call(API.get, `/v1/report/tier-income`, {
          params,
        })) as AxiosResponse<TierIncome[]>;
        yield put(
          actions.incomeSuccess({
            entries: data,
          })
        );
      } catch (error) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.incomeFailure());
        if (errorData && errorData.serviceType === 'snackbar') {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* watchSalePrice() {
  yield takeLatest(
    actions.Types.salePriceRequest,
    function* ({ payload }: ReturnType<typeof actions.salePriceRequest>) {
      const { timeline, startedAt, endedAt, ...props } = payload;
      const params = {
        ...props,
        startedAt,
        endedAt,
      };
      if (timeline === 'WEEK') {
        delete params.endedAt;
      }
      if (timeline === 'MONTH') {
        params.startedAt = startedAt;
        params.endedAt = endedAt;
      }
      try {
        const { data } = (yield call(API.get, `/v1/dashboard/sale`, {
          params,
        })) as AxiosResponse<{
          sale: SaleSummary[];
        }>;
        yield put(
          actions.salePriceSuccess({
            entries: data.sale,
          })
        );
      } catch (error) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.salePriceFailure());
        if (errorData && errorData.serviceType === 'snackbar') {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* saga() {
  yield all([
    fork(watchListMoistureGroup),
    fork(watchIncome),
    fork(watchSalePrice),
  ]);
}
export default saga;
