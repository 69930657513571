import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import { saveAs } from 'file-saver';
import API from 'services/defaultInstance';
import * as actions from './index';

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
import type {
  SummaryEntry,
  SaleSummaryByMoisture,
  SaleTransaction,
  InvoiceSummary,
  InvoiceTransaction,
  ReceiptSummary,
  ReceiptTransaction,
  SummariesByCustomer,
} from 'models/sale.report.v2';
import { plus } from 'utils/format-number';
import moment from 'moment';
function* listSaleSummaries({
  payload,
}: ReturnType<typeof actions.listSummariesRequest>) {
  const { keyName, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/dashboard/summary-sale`, {
      params,
    })) as AxiosResponse<{ sale: SaleSummaryByMoisture[] }>;
    const { timelines, data: purchases } = data.sale.reduce(
      (values, item) => {
        const key = `${item.tier.name}-${item.product.name}`;
        const getDataValue = values.data[key] ?? {
          ...item,
          lossWeight: '0',
          srcWeight: '0',
          dstWeight: '0',
          count: 0,
          srcPrice: {
            amount: '0',
            currency: item.srcPrice.currency,
          },
          dstAmount: {
            amount: '0',
            currency: item.dstAmount.currency,
          },
          summary: {
            [item.timeline.value]: {
              value: 0,
              label: keyName === 'amount' ? 'LAK' : 'KG',
            },
          },
          result: {
            count: 0,
            dstAmount: 0,
            dstPrice: 0,
            dstWeight: 0,
            lossWeight: 0,
            srcAmount: 0,
            srcPrice: 0,
            srcWeight: 0,
          },
        };
        const getTimeLineValue = getDataValue.summary[item.timeline.value] ?? {
          value: 0,
          label: keyName === 'amount' ? 'LAK' : 'KG',
        };
        return {
          data: {
            ...values.data,
            [key]: {
              ...getDataValue,
              summary: {
                ...getDataValue.summary,
                [item.timeline.value]: {
                  value: plus(
                    getTimeLineValue.value,
                    keyName === 'weight'
                      ? item.srcWeight
                      : item.srcAmount.amount
                  ),
                  label: getTimeLineValue.label,
                },
              },
              result: {
                count: plus(getDataValue.result.count, item.count),
                srcPrice: plus(
                  getDataValue.result.srcPrice,
                  item.srcPrice.amount
                ),
                dstPrice: plus(
                  getDataValue.result.dstPrice,
                  item.dstPrice.amount
                ),
                srcAmount: plus(
                  getDataValue.result.srcAmount,
                  item.srcAmount.amount
                ),
                dstAmount: plus(
                  getDataValue.result.dstAmount,
                  item.dstAmount.amount
                ),
                lossWeight: plus(
                  getDataValue.result.lossWeight,
                  item.lossWeight
                ),
                srcWeight: plus(getDataValue.result.srcWeight, item.srcWeight),
                dstWeight: plus(getDataValue.result.dstWeight, item.dstWeight),
              },
            },
          },
          timelines: {
            ...values.timelines,
            [item.timeline.value]: item.timeline.value,
          },
        };
      },
      { timelines: {}, data: {} } as {
        timelines: {
          [key: number]: number;
        };
        data: {
          [key: string]: SummaryEntry;
        };
      }
    );
    yield put(
      actions.listSummariesSuccess({
        entries: Object.values(purchases),
        timelines: Object.values(timelines).sort((a, b) => a - b),
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listSummariesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listSaleTransactions({
  payload,
}: ReturnType<typeof actions.listTransactionsRequest>) {
  const { result, isFirstRender, page, ...props } = payload;
  const resultObject = JSON.parse(result || '{}');
  if (typeof resultObject !== 'object') {
    yield put(actions.listTransactionsFailure());
    return;
  }
  const params = {
    ...props,
    tier: resultObject.tier,
    product: resultObject.product,
  };
  try {
    const { data } = (yield call(API.get, `/v1/sale/report/items`, {
      params,
    })) as AxiosResponse<{
      sale: SaleTransaction[];
      nextPageToken: string;
    }>;
    yield put(
      actions.listTransactionsSuccess({
        entries: data.sale,
        result: {
          count: 0,
          dstAmount: 0,
          dstPrice: 0,
          dstWeight: 0,
          lossWeight: 0,
          srcAmount: 0,
          srcPrice: 0,
          srcWeight: 0,
          ...resultObject,
        },
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listInvoiceSummaries({
  payload,
}: ReturnType<typeof actions.listInvoiceSummaryRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data } = (yield call(API.get, `/v1/sale/report/summary-invoices`, {
      params,
    })) as AxiosResponse<{
      invoices: InvoiceSummary[];
    }>;
    yield put(
      actions.listInvoiceSummarySuccess({
        entries: data.invoices,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listInvoiceSummaryFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listInvoiceTransactions({
  payload,
}: ReturnType<typeof actions.listInvoiceTransactionsRequest>) {
  const { result, isFirstRender, page, ...props } = payload;
  console.log(decodeURIComponent(result || '{}'));
  const resultObject = JSON.parse(decodeURIComponent(result || '{}'));
  if (typeof resultObject !== 'object') {
    yield put(actions.listInvoiceTransactionsFailure());
    return;
  }
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/sale/report/invoices`, {
      params,
    })) as AxiosResponse<{
      invoices: InvoiceTransaction[];
      nextPageToken: string;
    }>;
    yield put(
      actions.listInvoiceTransactionsSuccess({
        entries: data.invoices,
        result: resultObject,
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listInvoiceTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* invoiceTransactionsExport({
  payload,
}: ReturnType<typeof actions.invoiceTransactionsExportRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data } = yield call(
      API.post,
      `/v1/sale/report/export-invoices`,
      params
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(blob, `${moment().format('DD/MM/YYYY')}.csv`);
    yield put(actions.invoiceTransactionsExportSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.invoiceTransactionsExportFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* receiptTransactionsExport({
  payload,
}: ReturnType<typeof actions.receiptTransactionsExportRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data } = yield call(
      API.post,
      `/v1/sale/report/export-receipts`,
      params
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(blob, `${moment().format('DD/MM/YYYY')}.csv`);
    yield put(actions.receiptTransactionsExportSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.receiptTransactionsExportFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listReceiptSummaries({
  payload,
}: ReturnType<typeof actions.listInvoiceSummaryRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data } = (yield call(API.get, `/v1/sale/report/summary-receipts`, {
      params,
    })) as AxiosResponse<{
      receipts: ReceiptSummary[];
    }>;
    yield put(
      actions.listReceiptSummarySuccess({
        entries: data.receipts,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listReceiptSummaryFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listReceiptTransactions({
  payload,
}: ReturnType<typeof actions.listReceiptTransactionsRequest>) {
  const { result, isFirstRender, page, ...props } = payload;
  const resultObject = JSON.parse(decodeURIComponent(result || '{}'));

  if (typeof resultObject !== 'object') {
    yield put(actions.listReceiptTransactionsFailure());
    return;
  }
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/sale/report/receipts`, {
      params,
    })) as AxiosResponse<{
      receipts: ReceiptTransaction[];
      nextPageToken: string;
    }>;
    yield put(
      actions.listReceiptTransactionsSuccess({
        entries: data.receipts,
        result: {
          ...resultObject,
        },
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listReceiptTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* customerSummaries({
  payload,
}: ReturnType<typeof actions.saleCustomerSummariesRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data } = (yield call(
      API.get,
      `/v1/sale/report/group-by-tier-product-customer-type`,
      {
        params,
      }
    )) as AxiosResponse<{
      sale: SummariesByCustomer[];
    }>;
    yield put(
      actions.saleCustomerSummariesSuccess({
        entries: data.sale,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.saleCustomerSummariesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* customerTransactions({
  payload,
}: ReturnType<typeof actions.saleCustomerTransactionsRequest>) {
  const { page, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/sale/report/items`, {
      params,
    })) as AxiosResponse<{
      sale: SaleTransaction[];
      nextPageToken: string;
    }>;
    yield put(
      actions.saleCustomerTransactionsSuccess({
        entries: data.sale,
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.saleCustomerTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchListSaleSummaries() {
  yield takeLatest(actions.Types.listSummariesRequest, listSaleSummaries);
}
function* watchListSaleTransactions() {
  yield takeLatest(actions.Types.listTransactionsRequest, listSaleTransactions);
}
function* watchListInvoiceSummaries() {
  yield takeLatest(
    actions.Types.listInvoiceSummaryRequest,
    listInvoiceSummaries
  );
}
function* watchListInvoiceTransactions() {
  yield takeLatest(
    actions.Types.listInvoiceTransactionsRequest,
    listInvoiceTransactions
  );
}

function* watchListReceiptSummaries() {
  yield takeLatest(
    actions.Types.listReceiptSummaryRequest,
    listReceiptSummaries
  );
}
function* watchListReceiptTransactions() {
  yield takeLatest(
    actions.Types.listReceiptTransactionsRequest,
    listReceiptTransactions
  );
}
function* watchInvoiceTransactionsExportCsv() {
  yield takeLatest(
    actions.Types.invoiceTransactionsExportRequest,
    invoiceTransactionsExport
  );
}
function* watchReceiptTransactionsExportCsv() {
  yield takeLatest(
    actions.Types.receiptTransactionsExportRequest,
    receiptTransactionsExport
  );
}
function* watchCustomerSummaries() {
  yield takeLatest(actions.Types.customerSummariesRequest, customerSummaries);
}
function* watchCustomerTransactions() {
  yield takeLatest(
    actions.Types.customerTransactionsRequest,
    customerTransactions
  );
}
function* saga() {
  yield all([
    fork(watchListSaleSummaries),
    fork(watchListSaleTransactions),
    fork(watchListInvoiceSummaries),
    fork(watchListInvoiceTransactions),
    fork(watchListReceiptSummaries),
    fork(watchListReceiptTransactions),
    fork(watchInvoiceTransactionsExportCsv),
    fork(watchReceiptTransactionsExportCsv),
    fork(watchCustomerSummaries),
    fork(watchCustomerTransactions),
  ]);
}
export default saga;
