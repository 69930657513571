import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  QueryParams,
  SummaryByTier,
  SummaryByWallet,
  Transaction,
} from 'models/wallet.report.v2';

export interface InitialState {
  zones: {
    isLoading: boolean;
    entries: SummaryByTier[];
  };
  tiers: {
    isLoading: boolean;
    entries: SummaryByTier[];
  };
  wallets: {
    isLoading: boolean;
    entries: SummaryByWallet[];
  };
  transactions: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: Transaction[];
        pageToken: string;
      };
    };
  };
}
const initialState: InitialState = {
  zones: {
    entries: [],
    isLoading: false,
  },
  tiers: {
    entries: [],
    isLoading: false,
  },
  wallets: {
    entries: [],
    isLoading: false,
  },
  transactions: {
    isExporting: false,
    isLoading: false,
    lastPageToken: '',
    rowCount: 0,
    pages: {},
  },
};
export enum Types {
  listWalletsByZoneRequest = 'report/listWalletsByZoneRequest',
  listWalletsByZoneSuccess = 'report/listWalletsByZoneSuccess',
  listWalletsByZoneFailure = 'report/listWalletsByZoneFailure',
  listWalletsByTierRequest = 'report/listWalletsByTierRequest',
  listWalletsByTierSuccess = 'report/listWalletsByTierSuccess',
  listWalletsByTierFailure = 'report/listWalletsByTierFailure',
  listWalletAccountsRequest = 'report/listWalletAccountsRequest',
  listWalletAccountsSuccess = 'report/listWalletAccountsSuccess',
  listWalletAccountsFailure = 'report/listWalletAccountsFailure',
  listTransactionsRequest = 'report/listTransactionsRequest',
  listTransactionsSuccess = 'report/listTransactionsSuccess',
  listTransactionsFailure = 'report/listTransactionsFailure',
}
const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    listWalletsByZoneRequest: (state, _: PayloadAction<QueryParams>) => {
      state.zones.isLoading = true;
    },
    listWalletsByZoneSuccess: (
      state,
      action: PayloadAction<SummaryByTier[]>
    ) => {
      state.zones.entries = action.payload;
      state.zones.isLoading = false;
    },
    listWalletsByZoneFailure: (state) => {
      state.zones.isLoading = false;
    },
    listWalletsByTierRequest: (state, _: PayloadAction<QueryParams>) => {
      state.tiers.isLoading = true;
    },
    listWalletsByTierSuccess: (
      state,
      action: PayloadAction<SummaryByTier[]>
    ) => {
      state.tiers.entries = action.payload;
      state.tiers.isLoading = false;
    },
    listWalletsByTierFailure: (state) => {
      state.tiers.isLoading = false;
    },
    listWalletAccountsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.wallets.isLoading = true;
    },
    listWalletAccountsSuccess: (
      state,
      action: PayloadAction<SummaryByWallet[]>
    ) => {
      state.wallets.entries = action.payload;
      state.wallets.isLoading = false;
    },
    listWalletAccountsFailure: (state) => {
      state.wallets.isLoading = false;
    },
    listTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.transactions.isLoading = true;
    },
    listTransactionsSuccess: (
      state,
      action: PayloadAction<{
        entries: Transaction[];
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.transactions.isLoading = false;
      state.transactions.pages = {
        ...state.transactions.pages,
        [action.payload.page || 0]: {
          entries: action.payload.entries,
          pageToken: action.payload.pageToken,
        },
      };
      state.transactions.lastPageToken = action.payload.pageToken;
    },
    listTransactionsFailure: (state) => {
      state.transactions.isLoading = false;
    },
  },
});
export const {
  listWalletsByZoneRequest,
  listWalletsByZoneSuccess,
  listWalletsByZoneFailure,
  listWalletsByTierRequest,
  listWalletsByTierSuccess,
  listWalletsByTierFailure,
  listWalletAccountsRequest,
  listWalletAccountsSuccess,
  listWalletAccountsFailure,
  listTransactionsRequest,
  listTransactionsSuccess,
  listTransactionsFailure,
} = slice.actions;
export default slice.reducer;
