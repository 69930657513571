import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Purchase,
  SummaryEntry,
  QueryParams,
  Result,
  EstimateLoss,
  PurchaseGroupByPaymentMethod,
  PaymentMethodTx,
  RivalPurchasePrice
} from 'models/purchase.report.v2';

export interface InitialState {
  summaries: {
    isLoading: boolean;
    entries: SummaryEntry[];
    timelines: number[];
  };
  transactions: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    result: Result;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: Purchase[];
        pageToken: string;
      };
    };
  };
  moisture: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: Purchase[];
        pageToken: string;
      };
    };
  };
  estimateLoss: {
    isLoading: boolean;
    entries: EstimateLoss[];
  };
  paymentMethod: {
    isLoading: boolean;
    entries: PurchaseGroupByPaymentMethod[];
  };
  paymentTransactions: {
    isLoading: boolean;
    isExporting: boolean;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: PaymentMethodTx[];
        pageToken: string;
      };
    };
  };
  rivalPurchasePrice: {
    isLoading: boolean;
    isExporting: boolean;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: RivalPurchasePrice[];
        pageToken: string;
      };
    };
  };
}
const initialState: InitialState = {
  summaries: {
    entries: [],
    isLoading: false,
    timelines: [],
  },
  transactions: {
    isExporting: false,
    isLoading: false,
    lastPageToken: '',
    rowCount: 0,
    result: {
      amount: 0,
      count: 0,
      price: 0,
      weight: 0,
      tierPurchasePrice: 0,
    },
    pages: {},
  },
  moisture: {
    isExporting: false,
    isLoading: false,
    lastPageToken: '',
    rowCount: 0,
    pages: {},
  },
  estimateLoss: {
    entries: [],
    isLoading: false,
  },
  paymentMethod: {
    isLoading: false,
    entries: [],
  },
  paymentTransactions: {
    isLoading: false,
    isExporting: false,
    lastPageToken: '',
    pages: {},
  },
  rivalPurchasePrice: {
    isLoading: false,
    isExporting: false,
    lastPageToken: '',
    pages: {},
  },
};
export enum Types {
  listSummariesRequest = 'report/listSummariesRequest',
  listSummariesSuccess = 'report/listSummariesSuccess',
  listSummariesFailure = 'report/listSummariesFailure',
  listTransactionsRequest = 'report/listTransactionsRequest',
  listTransactionsSuccess = 'report/listTransactionsSuccess',
  listTransactionsFailure = 'report/listTransactionsFailure',
  listTransactionsExportRequest = 'report/listTransactionsExportRequest',
  listTransactionsExportSuccess = 'report/listTransactionsExportSuccess',
  listTransactionsExportFailure = 'report/listTransactionsExportFailure',
  listMoistureRequest = 'report/listMoistureRequest',
  listMoistureSuccess = 'report/listMoistureSuccess',
  listMoistureFailure = 'report/listMoistureFailure',
  exportMoistureRequest = 'report/exportPurchaseMoistureRequest',
  exportMoistureSuccess = 'report/exportPurchaseMoistureSuccess',
  exportMoistureFailure = 'report/exportPurchaseMoistureFailure',
  estimateLossRequest = 'report/estimateLossRequest',
  estimateLossSuccess = 'report/estimateLossSuccess',
  estimateLossFailure = 'report/estimateLossFailure',
  purchasePaymentMethodRequest = 'report/purchasePaymentMethodRequest',
  purchasePaymentMethodSuccess = 'report/purchasePaymentMethodSuccess',
  purchasePaymentMethodFailure = 'report/purchasePaymentMethodFailure',
  purchasePaymentMethodTxRequest = 'report/purchasePaymentMethodTxRequest',
  purchasePaymentMethodTxSuccess = 'report/purchasePaymentMethodTxSuccess',
  purchasePaymentMethodTxFailure = 'report/purchasePaymentMethodTxFailure',
  rivalPurchasePriceRequest = 'report/rivalPurchasePriceRequest',
  rivalPurchasePriceSuccess = 'report/rivalPurchasePriceSuccess',
  rivalPurchasePriceFailure = 'report/rivalPurchasePriceFailure',
}
const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    listSummariesRequest: (state, _: PayloadAction<QueryParams>) => {
      state.summaries.isLoading = true;
    },
    listSummariesSuccess: (
      state,
      action: PayloadAction<{
        timelines: number[];
        entries: SummaryEntry[];
      }>
    ) => {
      state.summaries.entries = action.payload.entries;
      state.summaries.timelines = action.payload.timelines;
      state.summaries.isLoading = false;
    },
    listSummariesFailure: (state) => {
      state.summaries.isLoading = false;
    },
    listTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.transactions.isLoading = true;
    },
    listTransactionsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: Purchase[];
        result: Result;
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.transactions.result = payload.result;
      state.transactions.isLoading = false;
      state.transactions.pages = {
        ...state.transactions.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.transactions.lastPageToken = payload.pageToken;
    },
    listTransactionsFailure: (state) => {
      state.transactions.isLoading = false;
    },
    listMoistureRequest: (state, _: PayloadAction<QueryParams>) => {
      state.moisture.isLoading = true;
    },
    listMoistureSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: Purchase[];
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.moisture.isLoading = false;
      state.moisture.pages = {
        ...state.moisture.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.moisture.lastPageToken = payload.pageToken;
    },
    listMoistureFailure: (state) => {
      state.moisture.isLoading = false;
    },
    estimateLossRequest: (state, _: PayloadAction<QueryParams>) => {
      state.estimateLoss.isLoading = true;
    },
    estimateLossSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: EstimateLoss[];
      }>
    ) => {
      state.estimateLoss.entries = payload.entries;
      state.estimateLoss.isLoading = false;
    },
    estimateLossFailure: (state) => {
      state.estimateLoss.isLoading = false;
    },
    purchasePaymentMethodRequest: (state, _: PayloadAction<QueryParams>) => {
      state.paymentMethod.isLoading = true;
    },
    purchasePaymentMethodSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: PurchaseGroupByPaymentMethod[];
      }>
    ) => {
      state.paymentMethod.entries = payload.entries;
      state.paymentMethod.isLoading = false;
    },
    purchasePaymentMethodFailure: (state) => {
      state.paymentMethod.isLoading = false;
    },
    purchasePaymentMethodTxRequest: (state, _: PayloadAction<QueryParams>) => {
      state.paymentTransactions.isLoading = true;
    },
    purchasePaymentMethodTxSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: PaymentMethodTx[];
        page?: number;
        pageToken: string;
      }>
    ) => {
      state.paymentTransactions.pages = {
        ...state.paymentTransactions.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.paymentTransactions.isLoading = false;
    },
    purchasePaymentMethodTxFailure: (state) => {
      state.paymentTransactions.isLoading = false;
    },
    rivalPurchasePriceRequest: (state, _: PayloadAction<QueryParams>) => {
      state.rivalPurchasePrice.isLoading = true;
    },
    rivalPurchasePriceSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: RivalPurchasePrice[];
        page?: number;
        pageToken: string;
      }>
    ) => {
      state.rivalPurchasePrice.pages = {
        ...state.rivalPurchasePrice.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.rivalPurchasePrice.isLoading = false;
    },
    rivalPurchasePriceFailure: (state) => {
      state.rivalPurchasePrice.isLoading = false;
    },
    exportPurchaseMoistureRequest: (state, _: PayloadAction<QueryParams>) => {
      state.moisture.isExporting = true;
    },
    exportPurchaseMoistureSuccess: (state) => {
      state.moisture.isExporting = false;
    },
    exportPurchaseMoistureFailure: (state) => {
      state.moisture.isExporting = false;
    },

  },
});
export const {
  listSummariesRequest,
  listSummariesSuccess,
  listSummariesFailure,
  listTransactionsFailure,
  listTransactionsRequest,
  listTransactionsSuccess,
  listMoistureRequest,
  listMoistureSuccess,
  listMoistureFailure,
  estimateLossFailure,
  estimateLossRequest,
  estimateLossSuccess,
  purchasePaymentMethodRequest,
  purchasePaymentMethodSuccess,
  purchasePaymentMethodFailure,
  purchasePaymentMethodTxRequest,
  purchasePaymentMethodTxSuccess,
  purchasePaymentMethodTxFailure,
  rivalPurchasePriceFailure,
  rivalPurchasePriceRequest,
  rivalPurchasePriceSuccess,
  exportPurchaseMoistureFailure,
  exportPurchaseMoistureRequest,
  exportPurchaseMoistureSuccess
} = slice.actions;
export default slice.reducer;
