import { lazy } from 'react';
import * as AdminPages from 'pages/super-admin';
import * as TierPages from 'pages/tier';
import * as AdminPage from 'pages/admin';
import * as Report from 'pages/report';
import { permissionKey } from 'utils';
import type { ResourceKey } from 'utils';

const Login = lazy(() => import('pages/Login'));
const Profile = lazy(() => import('pages/Profile'));

export type LayoutKey = 'ADMIN' | 'AUTH';
export interface Router {
  component: JSX.Element;
  name: string;
  id: string;
  children?: Router[];
  path: string;
  index?: boolean;
  resource?: ResourceKey | 'public';
  permission?: keyof typeof permissionKey;
}
export type Routes = {
  [key in LayoutKey]: Array<Router>;
};

const routes: Routes = {
  AUTH: [
    {
      component: <Login />,
      name: "login",
      id: "login",
      path: "/login",
      resource: "public",
    },
  ],
  ADMIN: [
    {
      component: <Profile />,
      name: "profile",
      id: "profile",
      path: "/profile",
      resource: "public",
    },
    //super admin
    {
      component: <AdminPages.ActivityLogging />,
      name: "activity-logging",
      id: "activity-logging",
      path: "/super-admin/activities",
      resource: "activities",
      permission: "read",
    },
    {
      component: <AdminPages.Categories />,
      name: "product-categories",
      id: "product-categories",
      path: "/super-admin/categories",
      resource: "admin-products",
      permission: "read",
    },
    {
      component: <AdminPages.MoistureProductPrice />,
      name: "tier-moisture-product-price",
      id: "tier-moisture-product-price",
      path: "/super-admin/product-prices",
      resource: "admin-purchasePrices",
      permission: "read",
      children: [
        {
          component: <AdminPages.ProductPricePendingAndApprove />,
          name: "moisture-product-price-pending-approve",
          id: "moisture-product-price-pending-approve",
          path: "pending-approve",
          resource: "admin-purchasePrices",
          permission: "read",
        },
        {
          component: <AdminPages.ProductPriceUnApproveAndReject />,
          name: "moisture-product-price-un-approve-reject",
          id: "moisture-product-price-un-approve-reject",
          path: "un-approve-reject",
          resource: "admin-purchasePrices",
          permission: "read",
        },
      ],
    },
    {
      component: <AdminPages.Company />,
      name: "company",
      id: "company",
      path: "/super-admin/company",
      resource: "companies",
      permission: "read",
    },
    {
      component: <AdminPages.Users />,
      name: "users-list",
      id: "users-list",
      path: "/super-admin/users",
      resource: "admin-users",
      permission: "read",
    },
    {
      component: <AdminPages.UserCreate />,
      name: "user-create",
      id: "users-create",
      path: "/super-admin/users/create",
      resource: "admin-users",
      permission: "create",
    },
    {
      component: <AdminPages.ExpenseTypes />,
      name: "expense-types",
      id: "expense-types",
      path: "/super-admin/expense-types",
      resource: "expenseTypes",
      permission: "read",
    },
    {
      component: <AdminPages.Zones />,
      name: "zones-list",
      id: "zones-list",
      path: "/super-admin/zones",
      resource: "admin-zones",
      permission: "read",
    },

    {
      component: <AdminPages.UserUpdate />,
      name: "user-update",
      id: "users-update",
      path: "/super-admin/users/:username",
      resource: "admin-users",
      permission: "update",
    },
    {
      component: <AdminPages.Seasons />,
      name: "seasons-create",
      id: "seasons-create",
      path: "/super-admin/seasons",
      resource: "seasons",
      permission: "read",
    },
    {
      component: <AdminPages.Customers />,
      name: "customers-create",
      id: "customers-create",
      path: "/super-admin/customers",
      resource: "admin-customers",
      permission: "read",
    },
    {
      component: <AdminPages.Wallets />,
      name: "wallets-list",
      id: "wallets-list",
      path: "/super-admin/wallets",
      resource: "admin-wallets",
      permission: "read",
    },
    {
      component: <AdminPages.Roles />,
      name: "roles-list",
      id: "roles-list",
      path: "/super-admin/roles",
      resource: "admin-roles",
      permission: "read",
    },
    {
      component: <AdminPages.RoleCreate />,
      name: "role-create",
      id: "role-create",
      path: "/super-admin/roles/create",
      resource: "admin-roles",
      permission: "create",
    },
    {
      component: <AdminPages.RoleUpdate />,
      name: "role-update",
      id: "role-update",
      path: "/super-admin/roles/:roleName",
      resource: "admin-roles",
      permission: "update",
    },
    {
      component: <AdminPages.Products />,
      name: "products",
      id: "products",
      path: "/super-admin/products",
      resource: "admin-products",
      permission: "read",
    },
    {
      component: <AdminPages.Product />,
      name: "super-admin-product",
      id: "super-admin-product",
      path: "/super-admin/products/:id",
      resource: "admin-products",
      permission: "read",
      children: [
        {
          component: <AdminPages.ProductDetail />,
          name: "super-admin-product-detail",
          id: "super-admin-product-detail",
          path: "detail",
          resource: "admin-products",
          permission: "read",
        },
        {
          component: <AdminPages.ProductProcess />,
          name: "super-admin-product-process",
          id: "super-admin-product-process",
          path: "process",
          resource: "admin-products",
          permission: "read",
        },
        {
          component: <AdminPages.ProductLossMoisture />,
          name: "super-admin-product-LossMoisture",
          id: "super-admin-product-LossMoisture",
          path: "loss-moisture-estimate",
          resource: "admin-products",
          permission: "read",
        },
        {
          component: <AdminPages.ProductLossEstimate />,
          name: "super-admin-loss-estimate",
          id: "super-admin-loss-estimate",
          path: "loss-estimate",
          resource: "admin-products",
          permission: "read",
        },
      ],
    },
    {
      component: <AdminPages.Tiers />,
      name: "tiers",
      id: "tiers",
      path: "/super-admin/tiers",
      resource: "admin-tiers",
      permission: "read",
    },
    {
      component: <AdminPages.TierCreate />,
      name: "tier-create",
      id: "tier-create",
      path: "/super-admin/tiers/create",
      resource: "admin-tiers",
      permission: "create",
    },
    {
      component: <AdminPages.TierUsers />,
      name: "tier-users",
      id: "tier-users",
      path: "/super-admin/tiers/:tierName/users",
      resource: "admin-tiers",
      permission: "read",
    },
    {
      component: <AdminPages.TierWallets />,
      name: "tier-wallets",
      id: "tier-wallets",
      path: "/super-admin/tiers/:tierName/wallets",
      resource: "admin-tiers",
      permission: "read",
    },
    {
      component: <AdminPages.TierCategories />,
      name: "tier-TierCategories",
      id: "tier-TierCategories",
      path: "/super-admin/tiers/:tierName/categories",
      resource: "admin-tiers",
      permission: "read",
    },
    {
      component: <AdminPages.ExchangeRates />,
      name: "super-admin-exchange-rates",
      id: "super-admin-exchange-rates",
      path: "/super-admin/exchange-rates",
      resource: "exchangeRates",
      permission: "read",
    },
    {
      component: <AdminPages.Report.Sales />,
      name: "super-admin-report-sales",
      id: "super-admin-report-sales",
      path: "/super-admin/report/sales",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SalesTransactions />,
      name: "super-admin-report-sales-transactions",
      id: "super-admin-report-sales-transactions",
      path: "/super-admin/report/sales/transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SummaryStocks />,
      name: "super-admin-report-inventories-stocks-summary",
      id: "super-admin-report-inventories-stocks-summary",
      path: "/super-admin/report/inventories-stocks-summary",
      resource: "admin-report-inventory",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SummaryLots />,
      name: "super-admin-report-inventories-lots-summary",
      id: "super-admin-report-inventories-lots-summary",
      path: "/super-admin/report/inventories-lots-summary",
      resource: "admin-report-inventory",
      permission: "read",
    },
    {
      component: <AdminPages.Report.EntitiesLots />,
      name: "super-admin-report-inventories-lots-entities",
      id: "super-admin-report-inventories-lots-entities",
      path: "/super-admin/report/inventories-lots-summary/entities",
      resource: "admin-report-inventory",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SalesInvoices />,
      name: "super-admin-report-sales-invoices-entities",
      id: "super-admin-report-sales-invoices-entities",
      path: "/super-admin/report/sales-invoices/entities",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SummaryInvoices />,
      name: "super-admin-report-sales-invoices-summary",
      id: "super-admin-report-sales-invoices-summary",
      path: "/super-admin/report/sales-invoices/summary",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SalesReceipts />,
      name: "super-admin-report-sales-receipts-entities",
      id: "super-admin-report-sales-receipts-entities",
      path: "/super-admin/report/sales-receipts/entities",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SummaryReceipts />,
      name: "super-admin-report-sales-receipts-summary",
      id: "super-admin-report-sales-receipts-summary",
      path: "/super-admin/report/sales-receipts/summary",
      resource: "admin-report-sale",
      permission: "read",
    },
    //TODO: set new get receipt and permission
    {
      component: <TierPages.SaleReceipt />,
      name: "tier-sale-receipt",
      id: "tier-sale-receipt",
      path: "/super-admin/report/sales-receipts/entities/:receiptId",
      resource: "sales",
      permission: "read",
    },
    {
      component: <AdminPages.Report.SummaryExpenses />,
      name: "super-admin-report-expenses-summary",
      id: "super-admin-report-expenses-summary",
      path: "/super-admin/report/expenses-summary",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <AdminPages.Report.Expenses />,
      name: "super-admin-report-expenses-entities",
      id: "super-admin-report-expenses-entities",
      path: "/super-admin/report/expenses-entities",
      resource: "admin-report-sale",
      permission: "read",
    },
    //TODO : add resource permission
    {
      component: <AdminPages.Report.WalletsAccounts />,
      name: "super-admin-report-wallets-accounts",
      id: "super-admin-report-wallets-accounts",
      path: "/super-admin/report/wallets-accounts",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <AdminPages.Report.WalletsTransactions />,
      name: "super-admin-report-wallets-transactions",
      id: "super-admin-report-wallets-transactions",
      path: "/super-admin/report/wallets-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    //TODO : Dashboards add resource permission
    // {
    //   component: <AdminPages.Dashboards.Lots />,
    //   name: 'super-admin-dashboards-lots',
    //   id: 'super-admin-dashboards-lots',
    //   path: '/super-admin/dashboard/lots',
    //   resource: 'admin-report-inventory',
    //   permission: 'read',
    // },
    // {
    //   component: <AdminPages.Dashboards.Dashboards />,
    //   name: 'super-admin-dashboards-home',
    //   id: 'super-admin-dashboards-home',
    //   path: '/super-admin/dashboard/home',
    //   resource: 'admin-report-inventory',
    //   permission: 'read',
    // },
    // {
    //   component: <AdminPages.Dashboards.Sales />,
    //   name: 'super-admin-dashboards-sales',
    //   id: 'super-admin-dashboards-sales',
    //   path: '/super-admin/dashboard/sales',
    //   resource: 'admin-report-sale',
    //   permission: 'read',
    // },
    // {
    //   component: <AdminPages.Dashboards.Purchases />,
    //   name: 'super-admin-dashboards-purchases',
    //   id: 'super-admin-dashboards-purchases',
    //   path: '/super-admin/dashboard/purchases',
    //   resource: 'admin-report-purchase',
    //   permission: 'read',
    // },
    //admin
    {
      component: <AdminPage.ProductPrices />,
      name: "admin-product-prices",
      id: "admin-product-prices",
      path: "/admin/product-prices",
      resource: "zone-purchasePrices",
      permission: "read",
    },
    //Tier
    {
      component: <TierPages.Menu />,
      name: "tier-menu",
      id: "tier-menu",
      path: "/tiers/:tierName/menu",
      resource: "public",
    },
    {
      component: <TierPages.MoistureProductPrice />,
      name: "tier-moisture-product-price",
      id: "tier-moisture-product-price",
      path: "/tiers/:tierName/moisture-product-price",
      resource: "purchasePrices",
      permission: "read",
      children: [
        {
          component: <TierPages.ProductPricePendingAndApprove />,
          name: "tier-moisture-product-price-pending-approve",
          id: "tier-moisture-product-price-pending-approve",
          path: "pending-approve",
          resource: "purchasePrices",
          permission: "read",
        },
        {
          component: <TierPages.ProductPriceUnApproveAndReject />,
          name: "tier-moisture-product-price-un-approve-reject",
          id: "tier-moisture-product-price-un-approve-reject",
          path: "un-approve-reject",
          resource: "purchasePrices",
          permission: "read",
        },
      ],
    },
    {
      component: <TierPages.RivalPurchasePrices />,
      name: "tier-rivalPurchasePrices",
      id: "tier-rivalPurchasePrices",
      path: "/tiers/:tierName/rival-purchase-prices",
      resource: "rivalPurchasePrices",
      permission: "read",
    },
    {
      component: <TierPages.Wallet />,
      name: "tier-purchasing-wallet",
      id: "tier-purchasing-wallet",
      path: "/tiers/:tierName/wallets",
      resource: "wallets",
      permission: "read",
    },
    {
      component: <TierPages.Expenses />,
      name: "tier-expenses",
      id: "tier-expenses",
      path: "/tiers/:tierName/expenses",
      resource: "expenses",
      permission: "read",
    },
    {
      component: <TierPages.Purchases />,
      name: "tier-purchasing-purchases",
      id: "tier-purchasing-purchases",
      path: "/tiers/:tierName/purchases",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <TierPages.Customers />,
      name: "tier-customers",
      id: "tier-customers",
      path: "/tiers/:tierName/customers",
      resource: "customers",
      permission: "read",
    },
    {
      component: <TierPages.CustomerCreate />,
      name: "tier-customer-create",
      id: "tier-customer-create",
      path: "/tiers/:tierName/customers/create",
      resource: "customers",
      permission: "create",
    },
    {
      component: <TierPages.CustomerUpdate />,
      name: "tier-customer-update",
      id: "tier-customer-update",
      path: "/tiers/:tierName/customers/update/:customerId",
      resource: "customers",
      permission: "update",
    },
    // {
    //   component: <TierPages.QualityCheck />,
    //   name: 'tier-purchasing-QualityCheck',
    //   id: 'tier-purchasing-QualityCheck',
    //   path: 'app/tiers/:tierName/qualitycheck',
    // },
    // {
    //   component: <TierPages.QualitycheckCreate />,
    //   name: 'tier-purchasing-QualitycheckCreate',
    //   id: 'tier-purchasing-QualitycheckCreate',
    //   path: 'app/tiers/:tierName/qualitycheck/create',
    // },
    {
      component: <TierPages.Purchase />,
      name: "tier-purchasing-Purchase",
      id: "tier-purchasing-Purchase",
      path: "tiers/:tierName/purchases/:purchaseId",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <TierPages.PurchasesSummaries />,
      name: "tier-PurchasesSummaries",
      id: "tier-PurchasesSummaries",
      path: "tiers/:tierName/purchases-summaries",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <TierPages.Audit />,
      name: "tier-audit",
      id: "tier-audit",
      path: "tiers/:tierName/audit",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <TierPages.PurchaseSummary />,
      name: "tier-PurchaseSummary",
      id: "tier-PurchaseSummary",
      path: "tiers/:tierName/purchases-summaries/:purchaseSummaryId",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <TierPages.InventoryStocks />,
      name: "tier-stocks",
      id: "tier-stocks",
      path: "tiers/:tierName/stocks",
      resource: "inventories",
      permission: "read",
    },
    {
      component: <TierPages.Stock />,
      name: "tier-stock",
      id: "tier-stock",
      path: "tiers/:tierName/stocks/:stockId",
      resource: "inventories",
      permission: "read",
      children: [
        {
          component: <TierPages.StockDetail />,
          name: "tier-stocks-detail",
          id: "tier-stocks-detail",
          path: "detail",
          resource: "inventories",
          permission: "read",
        },
        {
          component: <TierPages.StockProcess />,
          name: "tier-stock-process",
          id: "tier-stock-process",
          path: "process",
          resource: "inventories",
          permission: "read",
        },
      ],
    },

    {
      component: <TierPages.InventoryLots />,
      name: "tier-inventory-lots",
      id: "tier-inventory-lots",
      path: "tiers/:tierName/lots",
      resource: "inventories",
      permission: "read",
    },
    {
      component: <TierPages.InventoryLot />,
      name: "tier-inventory-lot",
      id: "tier-inventory-lot",
      path: "tiers/:tierName/lots/:lotId",
      resource: "inventories",
      permission: "read",
    },
    {
      component: <TierPages.SaleOrders />,
      name: "tier-sale-orders",
      id: "tier-sale-orders",
      path: "tiers/:tierName/sale/orders",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleOrder />,
      name: "tier-sale-order",
      id: "tier-sale-order",
      path: "tiers/:tierName/sale/orders/:orderId",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.CreateOrder />,
      name: "tier-sale-create-order",
      id: "tier-sale-create-order",
      path: "tiers/:tierName/sale/orders/create",
      resource: "sales",
      permission: "create",
    },
    {
      component: <TierPages.OrderUpdate />,
      name: "tier-sale-update-order",
      id: "tier-sale-update-order",
      path: "tiers/:tierName/sale/orders/update/:orderId",
      resource: "sales",
      permission: "create",
    },
    {
      component: <TierPages.SaleTransactions />,
      name: "tier-sale-transactions",
      id: "tier-sale-transactions",
      path: "tiers/:tierName/sale/transactions",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleTransaction />,
      name: "tier-sale-transaction",
      id: "tier-sale-transaction",
      path: "tiers/:tierName/sale/transactions/:transactionId",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.AuditSale />,
      name: "tier-sale-audit",
      id: "tier-sale-audit",
      path: "tiers/:tierName/sale-audit",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleTransactionCreate />,
      name: "tier-sale-transaction-create",
      id: "tier-sale-transaction-create",
      path: "tiers/:tierName/sale/transactions/create",
      resource: "sales",
      permission: "create",
    },
    {
      component: <TierPages.SaleInvoices />,
      name: "tier-sale-invoices",
      id: "tier-sale-invoices",
      path: "tiers/:tierName/sale/invoices",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleInvoice />,
      name: "tier-sale-invoice",
      id: "tier-sale-invoice",
      path: "tiers/:tierName/sale/invoices/:invoiceId",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleInvoiceUpdate />,
      name: "tier-sale-invoice-update",
      id: "tier-sale-invoice-update",
      path: "tiers/:tierName/sale/invoices/update/:invoiceId",
      resource: "sales",
      permission: "create",
    },
    {
      component: <TierPages.SaleInvoiceCreate />,
      name: "tier-sale-invoice-create",
      id: "tier-sale-invoice-create",
      path: "tiers/:tierName/sale/invoices/create",
      resource: "sales",
      permission: "create",
    },
    {
      component: <TierPages.SaleTransfers />,
      name: "tier-sale-transfers",
      id: "tier-sale-transfers",
      path: "tiers/:tierName/sale/transfers",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleReceipts />,
      name: "tier-sale-receipts",
      id: "tier-sale-receipts",
      path: "tiers/:tierName/sale/receipts",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleReceipt />,
      name: "tier-sale-receipt",
      id: "tier-sale-receipt",
      path: "tiers/:tierName/sale/receipts/:receiptId",
      resource: "sales",
      permission: "read",
    },
    {
      component: <TierPages.SaleReceiptCreate />,
      name: "tier-sale-receipt-create",
      id: "tier-sale-receipt-create",
      path: "tiers/:tierName/sale/invoices/:invoiceId/receipt-create",
      resource: "sales",
      permission: "create",
    },
    //Purchases Summaries
    {
      component: <Report.PurchasesSummaries />,
      name: "super-admin-report-purchases-summaries",
      id: "super-admin-report-purchases-summaries",
      path: "super-admin/report/purchases-summaries",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesSummaries />,
      name: "zone-report-purchases-summaries",
      id: "zone-report-purchases-summaries",
      path: "zone/report/purchases-summaries",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesSummaries />,
      name: "tier-report-purchases-summaries",
      id: "tier-report-purchases-summaries",
      path: "tiers/:tierName/report/purchases-summaries",
      resource: "purchases",
      permission: "read",
    },
    //Purchases transactions
    {
      component: <Report.PurchasesTransactions />,
      name: "admin-report-purchases-transactions",
      id: "admin-report-purchases-transactions",
      path: "admin/report/purchases-transactions",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.PurchasesTransactions />,
      name: "zone-report-purchases-transactions",
      id: "zone-report-purchases-transactions",
      path: "zone/report/purchases-transactions",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.PurchasesTransactions />,
      name: "tier-report-purchases-transactions",
      id: "tier-report-purchases-transactions",
      path: "tiers/:tierName/report/purchases-transactions",
      resource: "purchases",
      permission: "read",
    },
    //Purchases Moisture
    {
      component: <Report.PurchasesMoisture />,
      name: "admin-report-purchases-moisture",
      id: "admin-report-purchases-moisture",
      path: "admin/report/purchases-moisture",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.PurchasesMoisture />,
      name: "zone-report-purchases-moisture",
      id: "zone-report-purchases-moisture",
      path: "zone/report/purchases-moisture",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.PurchasesMoisture />,
      name: "tier-report-purchases-moisture",
      id: "tier-report-purchases-moisture",
      path: "tiers/:tierName/report/purchases-moisture",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesEstimateLoss />,
      name: "admin-report-purchases-estimate-loss",
      id: "admin-report-purchases-estimate-loss",
      path: "admin/report/purchases-estimate-loss",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesEstimateLoss />,
      name: "zone-report-purchases-estimate-loss",
      id: "zone-report-purchases-estimate-loss",
      path: "zone/report/purchases-estimate-loss",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesEstimateLoss />,
      name: "tier-report-purchases-estimate-loss",
      id: "tier-report-purchases-estimate-loss",
      path: "tiers/:tierName/report/purchases-estimate-loss",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethod />,
      name: "admin-report-purchases-paymentMethod",
      id: "admin-report-purchases-paymentMethod",
      path: "admin/report/purchases-paymentMethod",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethodTier />,
      name: "admin-report-purchases-paymentMethodTier",
      id: "admin-report-purchases-paymentMethodTier",
      path: "admin/report/purchases-paymentMethodTier",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethodCustomer />,
      name: "admin-report-purchases-paymentMethodCustomer",
      id: "admin-report-purchases-paymentMethodCustomer",
      path: "admin/report/purchases-paymentMethodCustomer",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethod />,
      name: "zone-report-purchases-paymentMethod",
      id: "zone-report-purchases-paymentMethod",
      path: "zone/report/purchases-paymentMethod",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethodTier />,
      name: "zone-report-purchases-paymentMethodTier",
      id: "zone-report-purchases-paymentMethodTier",
      path: "zone/report/purchases-paymentMethodTier",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethodCustomer />,
      name: "zone-report-purchases-paymentMethodCustomer",
      id: "zone-report-purchases-paymentMethodCustomer",
      path: "zone/report/purchases-paymentMethodCustomer",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethod />,
      name: "tier-report-purchases-paymentMethod",
      id: "tier-report-purchases-paymentMethod",
      path: "tiers/:tierName/report/purchases-paymentMethod",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethodTier />,
      name: "tier-report-purchases-paymentMethodTier",
      id: "tier-report-purchases-paymentMethodTier",
      path: "tiers/:tierName/report/purchases-paymentMethodTier",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.PurchasesPaymentMethodCustomer />,
      name: "tier-report-purchases-paymentMethodCustomer",
      id: "tier-report-purchases-paymentMethodCustomer",
      path: "tiers/:tierName/report/purchases-paymentMethodCustomer",
      resource: "purchases",
      permission: "read",
    },
    //lot summary weight
    {
      component: <Report.LotSummaryWeight />,
      name: "admin-report-lot-summary-weight",
      id: "admin-report-lot-summary-weight",
      path: "admin/report/lot-summary-weight",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.LotSummaryWeight />,
      name: "zone-report-lot-summary-weight",
      id: "zone-report-lot-summary-weight",
      path: "zone/report/lot-summary-weight",
      resource: "purchases",
      permission: "read",
    },
    {
      component: <Report.LotSummaryWeight />,
      name: "tier-report-lot-summary-weight",
      id: "tier-report-lot-summary-weight",
      path: "tiers/:tierName/report/lot-summary-weight",
      resource: "purchases",
      permission: "read",
    },
    //lot transactions
    {
      component: <Report.LotTransactions />,
      name: "admin-report-lot-transactions",
      id: "admin-report-lot-transactions",
      path: "admin/report/lot-transactions",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.LotTransactions />,
      name: "zone-report-lot-transactions",
      id: "zone-report-lot-transactions",
      path: "zone/report/lot-transactions",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.LotTransactions />,
      name: "tier-report-lot-transactions",
      id: "tier-report-lot-transactions",
      path: "tiers/:tierName/report/lot-transactions",
      resource: "purchases",
      permission: "read",
    },
    //lots loss
    {
      component: <Report.LotsLoss />,
      name: "admin-report-lot-loss",
      id: "admin-report-lot-loss",
      path: "admin/report/lots-loss",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.LotsLoss />,
      name: "zone-report-lot-loss",
      id: "zone-report-lot-loss",
      path: "zone/report/lots-loss",
      resource: "admin-report-purchase",
      permission: "read",
    },
    {
      component: <Report.LotsLoss />,
      name: "tier-report-lot-loss",
      id: "tier-report-lot-loss",
      path: "tiers/:tierName/report/lots-loss",
      resource: "purchases",
      permission: "read",
    },
    //sale summaries
    {
      component: <Report.SaleSummaries />,
      name: "admin-report-sale-summaries",
      id: "admin-report-sale-summaries",
      path: "admin/report/sale-summaries",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleSummaries />,
      name: "zone-report-sale-summaries",
      id: "zone-report-sale-summaries",
      path: "zone/report/sale-summaries",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleSummaries />,
      name: "tier-report-sale-summaries",
      id: "tier-report-sale-summaries",
      path: "tiers/:tierName/report/sale-summaries",
      resource: "sales",
      permission: "read",
    },
    //sale transactions
    {
      component: <Report.SaleTransactions />,
      name: "admin-report-sale-transactions",
      id: "admin-report-sale-transactions",
      path: "admin/report/sale-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleTransactions />,
      name: "zone-report-sale-transactions",
      id: "zone-report-sale-transactions",
      path: "zone/report/sale-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleTransactions />,
      name: "tier-report-sale-transactions",
      id: "tier-report-sale-transactions",
      path: "tiers/:tierName/report/sale-transactions",
      resource: "sales",
      permission: "read",
    },
    {
      component: <Report.SaleInvoices />,
      name: "admin-report-sale-invoices",
      id: "admin-report-sale-invoices",
      path: "admin/report/invoice-summaries",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleInvoices />,
      name: "zone-report-sale-invoices",
      id: "zone-report-sale-invoices",
      path: "zone/report/invoice-summaries",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleInvoices />,
      name: "tier-report-sale-invoices",
      id: "tier-report-sale-invoices",
      path: "tiers/:tierName/report/invoice-summaries",
      resource: "sales",
      permission: "read",
    },
    {
      component: <Report.SaleInvoicesTransactions />,
      name: "admin-report-sale-invoices-transactions",
      id: "admin-report-sale-invoices-transactions",
      path: "admin/report/invoice-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleInvoicesTransactions />,
      name: "zone-report-sale-invoices-transactions",
      id: "zone-report-sale-invoices-transactions",
      path: "zone/report/invoice-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleInvoicesTransactions />,
      name: "tier-report-sale-invoices-transactions",
      id: "tier-report-sale-invoices-transactions",
      path: "tiers/:tierName/report/invoice-transactions",
      resource: "sales",
      permission: "read",
    },
    {
      component: <Report.SaleReceipts />,
      name: "admin-report-sale-receipts",
      id: "admin-report-sale-receipts",
      path: "admin/report/receipt-summaries",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleReceipts />,
      name: "zone-report-sale-receipts",
      id: "zone-report-sale-receipts",
      path: "zone/report/receipt-summaries",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleReceipts />,
      name: "tier-report-sale-receipts",
      id: "tier-report-sale-receipts",
      path: "tiers/:tierName/report/receipt-summaries",
      resource: "sales",
      permission: "read",
    },
    {
      component: <Report.SaleReceiptsTransactions />,
      name: "admin-report-sale-receipts-transactions",
      id: "admin-report-sale-receipts-transactions",
      path: "admin/report/receipt-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleReceiptsTransactions />,
      name: "zone-report-sale-receipts-transactions",
      id: "zone-report-sale-receipts-transactions",
      path: "zone/report/receipt-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleReceiptsTransactions />,
      name: "tier-report-sale-receipts-transactions",
      id: "tier-report-sale-receipts-transactions",
      path: "tiers/:tierName/report/receipt-transactions",
      resource: "sales",
      permission: "read",
    },
    {
      component: <Report.SaleCustomers />,
      name: "admin-report-sale-customers",
      id: "admin-report-sale-customers",
      path: "admin/report/sale-customers",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleCustomers />,
      name: "zone-report-sale-customers",
      id: "zone-report-sale-customers",
      path: "zone/report/sale-customers",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleCustomers />,
      name: "tier-report-sale-customers",
      id: "tier-report-sale-customers",
      path: "tiers/:tierName/report/sale-customers",
      resource: "sales",
      permission: "read",
    },
    {
      component: <Report.SaleCustomersTransactions />,
      name: "admin-report-sale-customers-transactions",
      id: "admin-report-sale-customers-transactions",
      path: "admin/report/sale-customers-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleCustomersTransactions />,
      name: "zone-report-sale-customers-transactions",
      id: "zone-report-sale-customers-transactions",
      path: "zone/report/sale-customers-transactions",
      resource: "admin-report-sale",
      permission: "read",
    },
    {
      component: <Report.SaleCustomersTransactions />,
      name: "tier-report-sale-customers-transactions",
      id: "tier-report-sale-customers-transactions",
      path: "tiers/:tierName/report/sale-customers-transactions",
      resource: "sales",
      permission: "read",
    },
    //Dashboard
    {
      component: <Report.Dashboard />,
      name: "admin-dashboard",
      id: "admin-dashboard",
      path: "admin/dashboard",
      resource: "public",
    },
    {
      component: <Report.Dashboard />,
      name: "zone-dashboard",
      id: "zone-dashboard",
      path: "/zone/dashboard",
      resource: "public",
    },
    {
      component: <Report.Dashboard />,
      name: "tier-dashboard",
      id: "tier-dashboard",
      path: "/tiers/:tierName/dashboard",
      resource: "public",
    },
    //Profit & Loss
    {
      component: <Report.ProfitZones />,
      name: "admin-report-profit-zones",
      id: "admin-report-profit-zones",
      path: "admin/report/profit-zones",
      resource: "public",
    },
    {
      component: <Report.ProfitTiers />,
      name: "admin-report-profit-tiers",
      id: "admin-report-profit-tiers",
      path: "admin/report/profit-tiers",
      resource: "public",
    },
    {
      component: <Report.ProfitTiers />,
      name: "zone-report-profit-tiers",
      id: "zone-report-profit-tiers",
      path: "zone/report/profit-tiers",
      resource: "public",
    },
    //Profit customers
    {
      component: <Report.ProfitCustomers />,
      name: "admin-report-profit-customers",
      id: "admin-report-profit-customers",
      path: "admin/report/profit-customers",
      resource: "public",
    },
    {
      component: <Report.ProfitCustomerTxs />,
      name: "admin-report-profit-customers-transactions",
      id: "admin-report-profit-customers-txs",
      path: "admin/report/profit-customers-txs",
      resource: "public",
    },
    {
      component: <Report.ProfitCustomers />,
      name: "zone-report-profit-customers",
      id: "zone-report-profit-customers",
      path: "zone/report/profit-customers",
      resource: "public",
    },
    {
      component: <Report.ProfitCustomerTxs />,
      name: "zone-report-profit-customers-transactions",
      id: "zone-report-profit-customers-txs",
      path: "zone/report/profit-customers-txs",
      resource: "public",
    },
    {
      component: <Report.ProfitCustomers />,
      name: "tier-report-profit-customers",
      id: "tier-report-profit-customers",
      path: "tiers/:tierName/report/profit-customers",
      resource: "public",
    },
    {
      component: <Report.ProfitCustomerTxs />,
      name: "tier-report-profit-customers-transactions",
      id: "tier-report-profit-customers-txs",
      path: "tiers/:tierName/report/profit-customers-txs",
      resource: "public",
    },

    //wallet
    {
      component: <Report.WalletZones />,
      name: "admin-report-wallet-zones",
      id: "admin-report-wallet-zones",
      path: "admin/report/wallet-zones",
      resource: "public",
    },
    {
      component: <Report.WalletTiers />,
      name: "admin-report-wallet-tiers",
      id: "admin-report-wallet-tiers",
      path: "admin/report/wallet-tiers",
      resource: "public",
    },
    {
      component: <Report.WalletTiers />,
      name: "zone-report-wallet-tiers",
      id: "zone-report-wallet-tiers",
      path: "zone/report/wallet-tiers",
      resource: "public",
    },
    {
      component: <Report.WalletAccounts />,
      name: "admin-report-wallet-accounts",
      id: "admin-report-wallet-accounts",
      path: "admin/report/wallet-accounts",
      resource: "public",
    },
    {
      component: <Report.WalletAccounts />,
      name: "zone-report-wallet-accounts",
      id: "zone-report-wallet-accounts",
      path: "zone/report/wallet-accounts",
      resource: "public",
    },
    {
      component: <Report.WalletAccounts />,
      name: "tier-report-wallet-accounts",
      id: "tier-report-wallet-accounts",
      path: "tiers/:tierName/report/wallet-accounts",
      resource: "public",
    },
    {
      component: <Report.WalletTransactions />,
      name: "admin-report-wallet-transactions",
      id: "admin-report-wallet-transactions",
      path: "admin/report/wallet-transactions",
      resource: "public",
    },
    {
      component: <Report.WalletTransactions />,
      name: "zone-report-wallet-transactions",
      id: "zone-report-wallet-transactions",
      path: "zone/report/wallet-transactions",
      resource: "public",
    },
    {
      component: <Report.WalletTransactions />,
      name: "tier-report-wallet-transactions",
      id: "tier-report-wallet-transactions",
      path: "tiers/:tierName/report/wallet-transactions",
      resource: "public",
    },
    //Expenses Types
    {
      component: <Report.ExpensesTypes />,
      name: "admin-report-expenses-types",
      id: "admin-report-expenses-types",
      path: "admin/report/expenses-types",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypes />,
      name: "zone-report-expenses-types",
      id: "zone-report-expenses-types",
      path: "zone/report/expenses-types",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypes />,
      name: "tier-report-expenses-types",
      id: "tier-report-expenses-types",
      path: "tiers/:tierName/report/expenses-types",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypeZones />,
      name: "admin-report-expenses-type-zones",
      id: "admin-report-expenses-type-zones",
      path: "admin/report/expenses-type-zones",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypeTiers />,
      name: "admin-report-expenses-type-tiers",
      id: "admin-report-expenses-type-tiers",
      path: "admin/report/expenses-type-tiers",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypeTiers />,
      name: "zone-report-expenses-type-tiers",
      id: "zone-report-expenses-type-tiers",
      path: "zone/report/expenses-type-tiers",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypeTransactions />,
      name: "admin-report-expenses-type-transactions",
      id: "admin-report-expenses-type-transactions",
      path: "admin/report/expenses-type-transactions",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypeTransactions />,
      name: "zone-report-expenses-type-transactions",
      id: "zone-report-expenses-type-transactions",
      path: "zone/report/expenses-type-transactions",
      resource: "public",
    },
    {
      component: <Report.ExpensesTypeTransactions />,
      name: "tier-report-expenses-type-transactions",
      id: "tier-report-expenses-type-transactions",
      path: "tiers/:tierName/report/expenses-type-transactions",
      resource: "public",
    },
    //RivalPrices
    {
      component: <Report.RivalPrices />,
      name: "admin-report-rival-prices",
      id: "admin-report-rival-prices",
      path: "admin/report/rival-prices",
      resource: "public",
    },
    {
      component: <Report.RivalPrices />,
      name: "zone-report-rival-prices",
      id: "zone-report-rival-prices",
      path: "zone/report/rival-prices",
      resource: "public",
    },
  ],
};
export default routes;
