import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  QueryParams,
  StockSummary,
  Stock,
  Result,
  LotsSummary,
  Lot,
} from 'models/inventory.report.v2';

export interface InitialState {
  stocksSummary: {
    isLoading: boolean;
    entries: StockSummary[];
  };
  StocksTransaction: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    result: Result;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: Stock[];
        pageToken: string;
      };
    };
  };
  lotSummary: {
    isLoading: boolean;
    entries: LotsSummary[];
  };
  lotTransactions: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: Lot[];
        pageToken: string;
      };
    };
  };
}
const initialState: InitialState = {
  stocksSummary: {
    entries: [],
    isLoading: false,
  },
  StocksTransaction: {
    isExporting: false,
    isLoading: false,
    lastPageToken: '',
    rowCount: 0,
    result: {
      actualCost: '0',
      allVolumeKg: '0',
      paidCost: '0',
      price: '0',
      remainingVolumeKg: '0',
      tier: '',
    },
    pages: {},
  },
  lotSummary: {
    entries: [],
    isLoading: false,
  },
  lotTransactions: {
    isExporting: false,
    isLoading: false,
    lastPageToken: '',
    pages: {},
    rowCount: 0,
  },
};
export enum Types {
  listStocksSummaryRequest = 'report/listStocksSummaryRequest',
  listStocksSummarySuccess = 'report/listStocksSummarySuccess',
  listStocksSummaryFailure = 'report/listStocksSummaryFailure',
  listStockTransactionsRequest = 'report/listStockTransactionsRequest',
  listStockTransactionsSuccess = 'report/listStockTransactionsSuccess',
  listStockTransactionsFailure = 'report/listStockTransactionsFailure',
  listLotsSummaryRequest = 'report/listLotsSummaryRequest',
  listLotsSummarySuccess = 'report/listLotsSummarySuccess',
  listLotsSummaryFailure = 'report/listLotsSummaryFailure',
  listLotsTransactionsRequest = 'report/listLotsTransactionsRequest',
  listLotsTransactionsSuccess = 'report/listLotsTransactionsSuccess',
  listLotsTransactionsFailure = 'report/listLotsTransactionsFailure',
}
const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    listStocksSummaryRequest: (state, _: PayloadAction<QueryParams>) => {
      state.stocksSummary.isLoading = true;
    },
    listStocksSummarySuccess: (
      state,
      action: PayloadAction<{
        entries: StockSummary[];
      }>
    ) => {
      state.stocksSummary.entries = action.payload.entries;
      state.stocksSummary.isLoading = false;
    },
    listStocksSummaryFailure: (state) => {
      state.stocksSummary.isLoading = false;
    },
    listStockTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.StocksTransaction.isLoading = true;
    },
    listStockTransactionsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: Stock[];
        result: Result;
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.StocksTransaction.result = payload.result;
      state.StocksTransaction.isLoading = false;
      state.StocksTransaction.pages = {
        ...state.StocksTransaction.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.StocksTransaction.lastPageToken = payload.pageToken;
    },
    listStockTransactionsFailure: (state) => {
      state.StocksTransaction.isLoading = false;
    },
    listLotsSummaryRequest: (state, _: PayloadAction<QueryParams>) => {
      state.lotSummary.isLoading = true;
    },
    listLotsSummarySuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: LotsSummary[];
      }>
    ) => {
      state.lotSummary.entries = payload.entries;
      state.lotSummary.isLoading = false;
    },
    listLotsSummaryFailure: (state) => {
      state.lotSummary.isLoading = false;
    },
    listLotsTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.lotTransactions.isLoading = true;
    },
    listLotsTransactionsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: Lot[];
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.lotTransactions.isLoading = false;
      state.lotTransactions.pages = {
        ...state.lotTransactions.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.lotTransactions.lastPageToken = payload.pageToken;
    },
    listLotsTransactionsFailure: (state) => {
      state.lotTransactions.isLoading = false;
    },
  },
});
export const {
  listLotsSummaryFailure,
  listLotsSummaryRequest,
  listLotsSummarySuccess,
  listStocksSummaryFailure,
  listStocksSummaryRequest,
  listStocksSummarySuccess,
  listStockTransactionsFailure,
  listStockTransactionsRequest,
  listStockTransactionsSuccess,
  listLotsTransactionsFailure,
  listLotsTransactionsRequest,
  listLotsTransactionsSuccess,
} = slice.actions;
export default slice.reducer;
