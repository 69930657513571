import axios, { AxiosRequestConfig, Canceler as AxiosCanceler } from 'axios';
// import mockUpData from './mock-data';
import { mappingErrorCode } from 'utils';
import { refreshToken } from './publicInstance';
// import { history } from 'store/index';
const baseURL = process.env.REACT_APP_API_URL || '';
const baseInstance = axios.create({
  baseURL: baseURL,
  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
});
baseInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers = {
        'Authorization': `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
let isRefreshingToken = false;
let failedRequestsQueue: any[] = [];
baseInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const errorCode = mappingErrorCode(error);
    // Check if error is 401 and token was provided in original request
    if (!originalRequest?.retry && errorCode && errorCode === 'TOKEN_EXPIRED') {
      if (!isRefreshingToken) {
        // Prevent multiple token refresh attempts by setting a flag
        isRefreshingToken = true;

        const newAccessToken = await refreshToken();
        isRefreshingToken = false;
        if (newAccessToken) {
          // Retry all failed requests queued during token refresh process
          failedRequestsQueue.forEach((req) => req.resolve(newAccessToken));
          failedRequestsQueue = [];
          // Retry the original request with the new token
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          originalRequest.retry = true; // Prevent recursion
          return baseInstance(originalRequest);
        } else {
          // Token refresh failed, reject all queued requests
          failedRequestsQueue.forEach((req) => req.reject(error));
          failedRequestsQueue = [];
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          return Promise.reject(error);
        }
      }
      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          resolve: (token: string) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            originalRequest.retry = true;
            resolve(baseInstance(originalRequest));
          },
          reject: (err: unknown) => reject(err),
        });
      });
    }

    return Promise.reject(error);
  }
);
// baseInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalConfig = error.config;
//     const errorCode = mappingErrorCode(error);

//     if (!originalConfig?._retry && errorCode && errorCode === 'TOKEN_EXPIRED') {
//       // originalConfig._retry = true;
//       isRefreshingToken = true;
//       const newAccessToken = await refreshToken();
//       isRefreshingToken = false;
//       await setTimeout(() => {}, 500);
//       if (newAccessToken) {
//         failedRequestsQueue.forEach((req) => req.resolve(newAccessToken));
//         failedRequestsQueue = [];
//         originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;
//         originalConfig.retry = true; // Prevent recursion
//         return baseInstance.request(originalConfig);
//       }
//       history.push('/login');
//       return Promise.reject(error);
//     }

//     return Promise.reject(error);
//   }
// );
// if (process.env.REACT_APP_IS_MOCK_UP) {
//   mockUpData(baseInstance);
// }
//create CancelToken
export const CancelToken = axios.CancelToken;
export const createCancelToken = () => {
  return new CancelToken((cancel: AxiosCanceler) => cancel);
};
export type Canceler = AxiosCanceler;
export default baseInstance;
