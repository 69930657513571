import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
import type {
  StockSummary,
  Stock,
  LotsSummary,
  Lot,
} from 'models/inventory.report.v2';
function* listStocksSummary({
  payload,
}: ReturnType<typeof actions.listStocksSummaryRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data } = (yield call(API.get, `/v1/inventory/report/stocks`, {
      params,
    })) as AxiosResponse<{ stocks: StockSummary[] }>;

    yield put(
      actions.listStocksSummarySuccess({
        entries: data.stocks,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listStocksSummaryFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* listStockTransactions({
  payload,
}: ReturnType<typeof actions.listStockTransactionsRequest>) {
  const { result, isFirstRender, page, ...props } = payload;
  const resultObject = JSON.parse(result || '{}');

  if (typeof resultObject !== 'object') {
    yield put(actions.listStockTransactionsFailure());
    return;
  }
  if (props?.tier && props?.tier !== resultObject.tier) {
    yield put(actions.listStockTransactionsFailure());
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ',
        serviceType: 'snackbar',
      })
    );
    return;
  }
  const params = {
    ...props,
    tier: resultObject.tier,
    product: resultObject.product,
  };
  try {
    const { data } = (yield call(API.get, `/v1/report/stocks`, {
      params,
    })) as AxiosResponse<{ stocks: Stock[]; nextPageToken: string }>;
    yield put(
      actions.listStockTransactionsSuccess({
        entries: data.stocks,
        result: {
          actualCost: '0',
          allVolumeKg: '0',
          paidCost: '0',
          price: '0',
          remainingVolumeKg: '0',
          tier: '',
          ...resultObject,
        },
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listStockTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listLotsSummary({
  payload,
}: ReturnType<typeof actions.listLotsSummaryRequest>) {
  const { result, isFirstRender, page, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/inventory/report/summary-lots`, {
      params,
    })) as AxiosResponse<{ summaryLots: LotsSummary[] }>;
    yield put(
      actions.listLotsSummarySuccess({
        entries: data.summaryLots,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listLotsSummaryFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listLotsTransactions({
  payload,
}: ReturnType<typeof actions.listLotsTransactionsRequest>) {
  const { page, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/inventory/report/lots`, {
      params,
    })) as AxiosResponse<{ Lots: Lot[]; nextPageToken: string }>;
    yield put(
      actions.listLotsTransactionsSuccess({
        entries: data.Lots,
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listLotsTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchStocksSummary() {
  yield takeLatest(actions.Types.listStocksSummaryRequest, listStocksSummary);
}
function* watchListStockTransactions() {
  yield takeLatest(
    actions.Types.listStockTransactionsRequest,
    listStockTransactions
  );
}
function* watchListLotsSummary() {
  yield takeLatest(actions.Types.listLotsSummaryRequest, listLotsSummary);
}
function* watchListLotTransactions() {
  yield takeLatest(
    actions.Types.listLotsTransactionsRequest,
    listLotsTransactions
  );
}
function* saga() {
  yield all([
    fork(watchStocksSummary),
    fork(watchListStockTransactions),
    fork(watchListLotsSummary),
    fork(watchListLotTransactions),
  ]);
}
export default saga;
