import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
import type {
  SummaryByTier,
  SummaryByWallet,
  Transaction,
} from 'models/wallet.report.v2';
import { plus } from 'utils/format-number';

function* listWalletsByZone({
  payload,
}: ReturnType<typeof actions.listWalletsByZoneRequest>) {
  const { ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const {
      data: { summaryTxTier },
    } = (yield call(API.get, `v1/wallet/report/summary-tx-tier`, {
      params,
    })) as AxiosResponse<{ summaryTxTier: SummaryByTier[] }>;
    const entries = summaryTxTier.reduce(
      (summary, entry) => {
        const key = entry.zone.name + entry.currency;
        const value = summary[key] ?? {
          ...entry,
          balance: '0',
          expense: '0',
          income: '0',
        };
        return {
          ...summary,
          [key]: {
            ...value,
            balance: plus(value.balance, entry.balance).toString(),
            expense: plus(value.expense, entry.expense).toString(),
            income: plus(value.income, entry.income).toString(),
          },
        };
      },
      {} as {
        [key: string]: SummaryByTier;
      }
    );
    yield put(actions.listWalletsByZoneSuccess(Object.values(entries)));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listWalletsByZoneFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listWalletsByTier({
  payload,
}: ReturnType<typeof actions.listWalletsByTierRequest>) {
  const { ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const {
      data: { summaryTxTier },
    } = (yield call(API.get, `v1/wallet/report/summary-tx-tier`, {
      params,
    })) as AxiosResponse<{ summaryTxTier: SummaryByTier[] }>;
    yield put(actions.listWalletsByTierSuccess(summaryTxTier));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listWalletsByTierFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listAccounts({
  payload,
}: ReturnType<typeof actions.listWalletAccountsRequest>) {
  const { ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const {
      data: { summaryTxTier },
    } = (yield call(API.get, `v1/wallet/report/summary-tx-wallet`, {
      params,
    })) as AxiosResponse<{ summaryTxTier: SummaryByWallet[] }>;
    yield put(actions.listWalletAccountsSuccess(summaryTxTier));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listWalletAccountsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listTransactions({
  payload,
}: ReturnType<typeof actions.listTransactionsRequest>) {
  const { page, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const {
      data: { transactions, nextPageToken },
    } = (yield call(API.get, `/v1/wallet/report/transactions`, {
      params,
    })) as AxiosResponse<{
      transactions: Transaction[];
      nextPageToken: string;
    }>;
    // const entries = transactions.reduce((entries, entry) => {
    //   const matched =transactions.find(t=>t.referenceID===entry.referenceID);
    //   if(matched){
    //     return [
    //       ...entries,
    //       {
    //         matched,
    //       },
    //     ];
    //   }
    // },[]);
    yield put(
      actions.listTransactionsSuccess({
        entries: transactions,
        pageToken: nextPageToken,
        page: page,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchlistWalletsByZone() {
  yield takeLatest(actions.Types.listWalletsByZoneRequest, listWalletsByZone);
}
function* watchlistWalletsByTier() {
  yield takeLatest(actions.Types.listWalletsByTierRequest, listWalletsByTier);
}
function* watchlistAccounts() {
  yield takeLatest(actions.Types.listWalletAccountsRequest, listAccounts);
}
function* watchListTransactions() {
  yield takeLatest(actions.Types.listTransactionsRequest, listTransactions);
}

function* saga() {
  yield all([
    fork(watchlistWalletsByZone),
    fork(watchlistWalletsByTier),
    fork(watchlistAccounts),
    fork(watchListTransactions),
  ]);
}
export default saga;
