import { lazy } from 'react';

export const Dashboard = lazy(() => import('./dashboard'));
export const PurchasesSummaries = lazy(() => import('./purchases/Summaries'));
export const PurchasesTransactions = lazy(
  () => import('./purchases/Transactions')
);
export const PurchasesMoisture = lazy(() => import('./purchases/Moisture'));
export const PurchasesEstimateLoss = lazy(
  () => import('./purchases/EstimateLoss')
);
export const PurchasesPaymentMethod = lazy(
  () => import('./purchases/PaymentMethod')
);
export const PurchasesPaymentMethodTier = lazy(
  () => import('./purchases/PaymentMethodTier')
);
export const PurchasesPaymentMethodCustomer = lazy(
  () => import('./purchases/PaymentMethodCustomer')
);
export const LotSummaryWeight = lazy(
  () => import('./inventory/LotSummaryWeight')
);
export const LotTransactions = lazy(
  () => import('./inventory/LotTransactions')
);
export const LotsLoss = lazy(() => import('./inventory/LotsLoss'));
export const SaleSummaries = lazy(() => import('./sale/SaleSummaries'));
export const SaleTransactions = lazy(() => import('./sale/SaleTransactions'));
export const SaleInvoices = lazy(() => import('./sale/Invoices'));
export const SaleInvoicesTransactions = lazy(
  () => import('./sale/InvoicesTransactions')
);
export const SaleReceipts = lazy(() => import('./sale/Receipts'));
export const SaleReceiptsTransactions = lazy(
  () => import('./sale/ReceiptsTransactions')
);
export const SaleCustomers = lazy(() => import('./sale/Customers'));
export const SaleCustomersTransactions = lazy(
  () => import('./sale/CustomersTransactions')
);
export const ProfitZones = lazy(() => import('./profit/Zones'));
export const ProfitTiers = lazy(() => import('./profit/Tiers'));
export const ProfitCustomers = lazy(() => import('./profit/Customers'));
export const ProfitCustomerTxs = lazy(() => import("./profit/CustomerTxs"));
export const WalletZones = lazy(() => import('./wallet/Zones'));
export const WalletTiers = lazy(() => import('./wallet/Tiers'));
export const WalletAccounts = lazy(() => import('./wallet/Accounts'));
export const WalletTransactions = lazy(() => import('./wallet/Transactions'));
export const ExpensesTypes = lazy(() => import('./expenses/ExpensesTypes'));
export const ExpensesTypeZones = lazy(() => import('./expenses/Zones'));
export const ExpensesTypeTiers = lazy(() => import('./expenses/Tiers'));
export const ExpensesTypeTransactions = lazy(
  () => import('./expenses/Transactions')
);
export const RivalPrices = lazy(() => import("./RivalPrices"));
