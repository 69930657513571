import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  SaleTransaction,
  SummaryEntry,
  QueryParams,
  Result,
  InvoiceSummary,
  InvoiceTransaction,
  ReceiptSummary,
  ReceiptTransaction,
  SummariesByCustomer,
} from 'models/sale.report.v2';

export interface InitialState {
  summaries: {
    isLoading: boolean;
    entries: SummaryEntry[];
    timelines: number[];
  };
  transactions: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    result: Result;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: SaleTransaction[];
        pageToken: string;
      };
    };
  };
  invoiceSummary: {
    isLoading: boolean;
    entries: InvoiceSummary[];
  };
  invoiceTransactions: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    result?: InvoiceSummary;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: InvoiceTransaction[];
        pageToken: string;
      };
    };
  };
  receiptSummary: {
    isLoading: boolean;
    entries: ReceiptSummary[];
  };
  receiptTransactions: {
    isLoading: boolean;
    rowCount: number;
    isExporting: boolean;
    result?: ReceiptSummary;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: ReceiptTransaction[];
        pageToken: string;
      };
    };
  };
  customerSummaries: {
    isLoading: boolean;
    entries: SummariesByCustomer[];
  };
  customerTransactions: {
    isLoading: boolean;
    isExporting: boolean;
    lastPageToken: string;
    pages: {
      [key: number]: {
        entries: SaleTransaction[];
        pageToken: string;
      };
    };
  };
}
const initialState: InitialState = {
  summaries: {
    entries: [],
    isLoading: false,
    timelines: [],
  },
  transactions: {
    isExporting: false,
    isLoading: false,
    lastPageToken: '',
    rowCount: 0,
    result: {
      count: 0,
      dstAmount: 0,
      dstPrice: 0,
      dstWeight: 0,
      lossWeight: 0,
      srcAmount: 0,
      srcPrice: 0,
      srcWeight: 0,
    },
    pages: {},
  },
  invoiceSummary: {
    isLoading: false,
    entries: [],
  },
  invoiceTransactions: {
    isLoading: false,
    rowCount: 0,
    isExporting: false,
    lastPageToken: '',
    pages: {},
  },
  receiptSummary: {
    isLoading: false,
    entries: [],
  },
  receiptTransactions: {
    isLoading: false,
    rowCount: 0,
    isExporting: false,
    lastPageToken: '',
    pages: {},
  },
  customerSummaries: {
    isLoading: false,
    entries: [],
  },
  customerTransactions: {
    isLoading: false,
    isExporting: false,
    lastPageToken: '',
    pages: {},
  },
};
export enum Types {
  listSummariesRequest = 'report/listSaleSummariesRequest',
  listSummariesSuccess = 'report/listSaleSummariesSuccess',
  listSummariesFailure = 'report/listSaleSummariesFailure',
  listTransactionsRequest = 'report/listSaleTransactionsRequest',
  listTransactionsSuccess = 'report/listSaleTransactionsSuccess',
  listTransactionsFailure = 'report/listSaleTransactionsFailure',
  listInvoiceSummaryRequest = 'report/listInvoiceSummaryRequest',
  listInvoiceSummarySuccess = 'report/listInvoiceSummarySuccess',
  listInvoiceSummaryFailure = 'report/listInvoiceSummaryFailure',
  listInvoiceTransactionsRequest = 'report/listInvoiceTransactionsRequest',
  listInvoiceTransactionsSuccess = 'report/listInvoiceTransactionsSuccess',
  listInvoiceTransactionsFailure = 'report/listInvoiceTransactionsFailure',
  invoiceTransactionsExportRequest = 'report/invoiceTransactionsExportRequest',
  invoiceTransactionsExportSuccess = 'report/invoiceTransactionsExportSuccess',
  invoiceTransactionsExportFailure = 'report/invoiceTransactionsExportFailure',
  listReceiptSummaryRequest = 'report/listReceiptSummaryRequest',
  listReceiptSummarySuccess = 'report/listReceiptSummarySuccess',
  listReceiptSummaryFailure = 'report/listReceiptSummaryFailure',
  listReceiptTransactionsRequest = 'report/listReceiptTransactionsRequest',
  listReceiptTransactionsSuccess = 'report/listReceiptTransactionsSuccess',
  listReceiptTransactionsFailure = 'report/listReceiptTransactionsFailure',
  receiptTransactionsExportRequest = 'report/receiptTransactionsExportRequest',
  receiptTransactionsExportSuccess = 'report/receiptTransactionsExportSuccess',
  receiptTransactionsExportFailure = 'report/receiptTransactionsExportFailure',
  customerSummariesRequest = 'report/saleCustomerSummariesRequest',
  customerSummariesSuccess = 'report/saleCustomerSummariesSuccess',
  customerSummariesFailure = 'report/saleCustomerSummariesFailure',
  customerTransactionsRequest = 'report/saleCustomerTransactionsRequest',
  customerTransactionsSuccess = 'report/saleCustomerTransactionsSuccess',
  customerTransactionsFailure = 'report/saleCustomerTransactionsFailure',
}
const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    listSummariesRequest: (state, _: PayloadAction<QueryParams>) => {
      state.summaries.isLoading = true;
    },
    listSummariesSuccess: (
      state,
      action: PayloadAction<{
        timelines: number[];
        entries: SummaryEntry[];
      }>
    ) => {
      state.summaries.entries = action.payload.entries;
      state.summaries.timelines = action.payload.timelines;
      state.summaries.isLoading = false;
    },
    listSummariesFailure: (state) => {
      state.summaries.isLoading = false;
    },
    listTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.transactions.isLoading = true;
    },
    listTransactionsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        entries: SaleTransaction[];
        result: Result;
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.transactions.result = payload.result;
      state.transactions.isLoading = false;
      state.transactions.pages = {
        ...state.transactions.pages,
        [payload.page || 0]: {
          entries: payload.entries,
          pageToken: payload.pageToken,
        },
      };
      state.transactions.lastPageToken = payload.pageToken;
    },
    listTransactionsFailure: (state) => {
      state.transactions.isLoading = false;
    },
    listInvoiceSummaryRequest: (state, _: PayloadAction<QueryParams>) => {
      state.invoiceSummary.isLoading = true;
    },
    listInvoiceSummarySuccess: (
      state,
      action: PayloadAction<{
        entries: InvoiceSummary[];
      }>
    ) => {
      state.invoiceSummary.entries = action.payload.entries;
      state.invoiceSummary.isLoading = false;
    },
    listInvoiceSummaryFailure: (state) => {
      state.invoiceSummary.isLoading = false;
    },
    listInvoiceTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.invoiceTransactions.isLoading = true;
    },
    listInvoiceTransactionsSuccess: (
      state,
      action: PayloadAction<{
        entries: InvoiceTransaction[];
        result: InvoiceSummary;
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.invoiceTransactions.result = action.payload.result;
      state.invoiceTransactions.isLoading = false;
      state.invoiceTransactions.pages = {
        ...state.invoiceTransactions.pages,
        [action.payload.page || 0]: {
          entries: action.payload.entries,
          pageToken: action.payload.pageToken,
        },
      };
      state.invoiceTransactions.lastPageToken = action.payload.pageToken;
    },
    listInvoiceTransactionsFailure: (state) => {
      state.invoiceTransactions.isLoading = false;
    },
    invoiceTransactionsExportRequest: (
      state,
      _: PayloadAction<QueryParams>
    ) => {
      state.invoiceTransactions.isExporting = true;
    },
    invoiceTransactionsExportSuccess: (state) => {
      state.invoiceTransactions.isExporting = false;
    },
    invoiceTransactionsExportFailure: (state) => {
      state.invoiceTransactions.isExporting = false;
    },
    listReceiptSummaryRequest: (state, _: PayloadAction<QueryParams>) => {
      state.receiptSummary.isLoading = true;
    },
    listReceiptSummarySuccess: (
      state,
      action: PayloadAction<{
        entries: ReceiptSummary[];
      }>
    ) => {
      state.receiptSummary.entries = action.payload.entries;
      state.receiptSummary.isLoading = false;
    },
    listReceiptSummaryFailure: (state) => {
      state.receiptSummary.isLoading = false;
    },
    listReceiptTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.receiptTransactions.isLoading = true;
    },
    listReceiptTransactionsSuccess: (
      state,
      action: PayloadAction<{
        entries: ReceiptTransaction[];
        result: ReceiptSummary;
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.receiptTransactions.result = action.payload.result;
      state.receiptTransactions.isLoading = false;
      state.receiptTransactions.pages = {
        ...state.receiptTransactions.pages,
        [action.payload.page || 0]: {
          entries: action.payload.entries,
          pageToken: action.payload.pageToken,
        },
      };
      state.receiptTransactions.lastPageToken = action.payload.pageToken;
    },
    listReceiptTransactionsFailure: (state) => {
      state.receiptTransactions.isLoading = false;
    },
    receiptTransactionsExportRequest: (
      state,
      _: PayloadAction<QueryParams>
    ) => {
      state.receiptTransactions.isExporting = true;
    },
    receiptTransactionsExportSuccess: (state) => {
      state.receiptTransactions.isExporting = false;
    },
    receiptTransactionsExportFailure: (state) => {
      state.receiptTransactions.isExporting = false;
    },
    saleCustomerTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.customerTransactions.isLoading = true;
    },
    saleCustomerTransactionsSuccess: (
      state,
      action: PayloadAction<{
        entries: SaleTransaction[];
        pageToken: string;
        page?: number;
      }>
    ) => {
      state.customerTransactions.isLoading = false;
      state.customerTransactions.pages = {
        ...state.customerTransactions.pages,
        [action.payload.page || 0]: {
          entries: action.payload.entries,
          pageToken: action.payload.pageToken,
        },
      };
      state.customerTransactions.lastPageToken = action.payload.pageToken;
    },
    saleCustomerTransactionsFailure: (state) => {
      state.customerTransactions.isLoading = false;
    },
    saleCustomerSummariesRequest: (state, _: PayloadAction<QueryParams>) => {
      state.customerSummaries.isLoading = true;
    },
    saleCustomerSummariesSuccess: (
      state,
      action: PayloadAction<{
        entries: SummariesByCustomer[];
      }>
    ) => {
      state.customerSummaries.isLoading = false;
      state.customerSummaries.entries = action.payload.entries;
    },
    saleCustomerSummariesFailure: (state) => {
      state.customerSummaries.isLoading = false;
    },
  },
});
export const {
  listSummariesRequest,
  listSummariesSuccess,
  listSummariesFailure,
  listTransactionsFailure,
  listTransactionsRequest,
  listTransactionsSuccess,
  listInvoiceSummaryFailure,
  listInvoiceSummaryRequest,
  listInvoiceSummarySuccess,
  listInvoiceTransactionsFailure,
  listInvoiceTransactionsRequest,
  listInvoiceTransactionsSuccess,
  listReceiptSummaryFailure,
  listReceiptSummaryRequest,
  listReceiptSummarySuccess,
  listReceiptTransactionsFailure,
  listReceiptTransactionsRequest,
  listReceiptTransactionsSuccess,
  invoiceTransactionsExportRequest,
  invoiceTransactionsExportSuccess,
  invoiceTransactionsExportFailure,
  receiptTransactionsExportFailure,
  receiptTransactionsExportRequest,
  receiptTransactionsExportSuccess,
  saleCustomerSummariesFailure,
  saleCustomerSummariesRequest,
  saleCustomerSummariesSuccess,
  saleCustomerTransactionsFailure,
  saleCustomerTransactionsRequest,
  saleCustomerTransactionsSuccess,
} = slice.actions;
export default slice.reducer;
