import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
import type {
  ExpenseTypeSummary,
  ExpenseTransaction,
  ExpenseTypeGroupByTier,
} from 'models/expense.report.v2';
import { plus } from 'utils/format-number';

function* listExpensesTypes({
  payload,
}: ReturnType<typeof actions.listExpensesTypesRequest>) {
  const { ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const {
      data: { expenseTypes },
    } = (yield call(API.get, `/v1/finance/report/summary-expense-type`, {
      params,
    })) as AxiosResponse<{ expenseTypes: ExpenseTypeSummary[] }>;
    yield put(actions.listExpensesTypesSuccess(expenseTypes));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listExpensesTypesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listGroupByZone({
  payload,
}: ReturnType<typeof actions.listGroupByZoneRequest>) {
  const { result, ...props } = payload;
  const params = {
    ...props,
  };
  const resultObject = JSON.parse(decodeURIComponent(result || '{}'));

  if (typeof resultObject !== 'object') {
    yield put(actions.listGroupByZoneFailure());
    return;
  }
  try {
    const { data } = (yield call(API.get, `/v1/report/tier-expenses`, {
      params,
    })) as AxiosResponse<ExpenseTypeGroupByTier[]>;
    const entries = data.reduce(
      (summary, entry) => {
        const key = entry.zone.name + entry.expenseType.name;
        const value = summary[key] ?? {
          ...entry,
          amount: {
            amount: '0',
            currency: entry.amount.currency,
          },
        };
        return {
          ...summary,
          [key]: {
            ...value,
            amount: {
              amount: plus(value.amount.amount, entry.amount.amount).toString(),
              currency: entry.amount.currency,
            },
          },
        };
      },
      {} as {
        [key: string]: ExpenseTypeGroupByTier;
      }
    );
    yield put(
      actions.listGroupByZoneSuccess({
        entries: Object.values(entries),
        result: resultObject,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listGroupByZoneFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listGroupByTier({
  payload,
}: ReturnType<typeof actions.listGroupByTierRequest>) {
  const { result, ...props } = payload;
  const params = {
    ...props,
  };
  const resultObject = JSON.parse(decodeURIComponent(result || '{}'));
  if (typeof resultObject !== 'object') {
    yield put(actions.listGroupByTierFailure());
    return;
  }
  try {
    const { data } = (yield call(API.get, `/v1/report/tier-expenses`, {
      params,
    })) as AxiosResponse<ExpenseTypeGroupByTier[]>;
    const entries = data.reduce(
      (summary, entry) => {
        const key = entry.tier.name + entry.expenseType.name;
        const value = summary[key] ?? {
          ...entry,
          amount: {
            amount: '0',
            currency: entry.amount.currency,
          },
        };
        return {
          ...summary,
          [key]: {
            ...value,
            amount: {
              amount: plus(value.amount.amount, entry.amount.amount).toString(),
              currency: entry.amount.currency,
            },
          },
        };
      },
      {} as {
        [key: string]: ExpenseTypeGroupByTier;
      }
    );
    yield put(
      actions.listGroupByTierSuccess({
        entries: Object.values(entries),
        result: resultObject,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listGroupByTierFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* listTransactions({
  payload,
}: ReturnType<typeof actions.listExTypesTxsRequest>) {
  const { page, result, ...props } = payload;
  const params = {
    ...props,
  };
  const objResult = JSON.parse(decodeURIComponent(result || '{}'));
  if (!objResult || typeof objResult !== 'object') {
    yield put(actions.listExTypesTxsFailure());
    return;
  }
  try {
    const {
      data: { expenses, nextPageToken },
    } = (yield call(API.get, `/v1/finance/report/expenses`, {
      params,
    })) as AxiosResponse<{
      expenses: ExpenseTransaction[];
      nextPageToken: string;
    }>;
    yield put(
      actions.listExTypesTxsSuccess({
        entries: expenses,
        pageToken: nextPageToken,
        page: page,
        result: objResult,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listExTypesTxsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchListExpensesTypes() {
  yield takeLatest(actions.Types.listExpensesTypesRequest, listExpensesTypes);
}
function* watchListGroupByZone() {
  yield takeLatest(actions.Types.listGroupByZoneRequest, listGroupByZone);
}
function* watchListGroupByTier() {
  yield takeLatest(actions.Types.listGroupByTierRequest, listGroupByTier);
}

function* watchListTransactions() {
  yield takeLatest(actions.Types.listExTypesTxsRequest, listTransactions);
}

function* saga() {
  yield all([
    fork(watchListExpensesTypes),
    fork(watchListGroupByZone),
    fork(watchListGroupByTier),
    fork(watchListTransactions),
  ]);
}
export default saga;
