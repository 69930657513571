import { Decimal } from 'decimal.js';

// export const newDecimal = (value: string | number) => new Decimal(value);
export const toDecimal = (value?: string | number): Decimal => {
  if (typeof value === 'string' || typeof value === 'number') {
    return new Decimal(value);
  }
  return new Decimal(0);
};
export const sumArray = ({
  arr,
  decimalPlaces,
}: {
  arr: (number | string)[];
  decimalPlaces?: number;
}) => {
  const totalSum = arr.reduce((sum, item) => {
    return sum.plus(new Decimal(item));
  }, new Decimal(0));
  return totalSum.toFixed(decimalPlaces);
};
export const plus = (
  a: string | number,
  b: string | number
  // decimalPlaces?: number
) => {
  const result = new Decimal(a || 0).plus(b || 0);
  return result.toNumber();
};
export const minus = <T extends number | undefined>(
  a: string | number,
  b: string | number,
  decimalPlaces?: T
): formatNumberResult<T> => {
  const result = new Decimal(a).minus(b);
  if (decimalPlaces) {
    return result.isNaN()
      ? ('0' as formatNumberResult<T>)
      : (result.toNumber().toLocaleString('en-US', {
          maximumFractionDigits: decimalPlaces,
          notation: 'standard',
        }) as formatNumberResult<T>);
  }
  return result.toNumber() as formatNumberResult<T>;
};
export const divide = (
  a: string | number,
  b: string | number,
  decimalPlaces?: number
) => {
  const result = new Decimal(a || 0).dividedBy(b || 0);
  if (decimalPlaces) {
    return result.isNaN()
      ? '0'
      : result.toNumber().toLocaleString('en-US', {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
  }
  return result.toNumber();
};
type formatNumberResult<T> = Exclude<T, number> extends number
  ? string
  : number;
export const formatNumber = <T extends number | undefined = undefined>(
  number: string | number,
  decimalPlaces?: T
): formatNumberResult<T> => {
  const decimalValue = toDecimal(number);
  if (!decimalPlaces) return decimalValue.toNumber() as formatNumberResult<T>;
  if (decimalValue.isNaN() || decimalValue.isZero())
    return '0' as formatNumberResult<T>;
  return decimalValue.toNumber().toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }) as formatNumberResult<T>;
};
export const formatNumberWithCommas = (n: string | number): string => {
  return n.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
export const formatCurrency = (number: string | number) => {
  if (number === undefined || number === null || number === 'null') return 0;
  const decimalValue = new Decimal(number);
  if (decimalValue.isNaN()) return '0';
  if (decimalValue.gte(1e6)) {
    const millions = decimalValue.div(1e6).toNumber();
    return formatNumberWithCommas(millions) + ' M';
  } else if (decimalValue.gte(1e3)) {
    const thousands = decimalValue.div(1e3).toNumber();
    return formatNumberWithCommas(thousands) + ' K';
  }
  return formatNumberWithCommas(decimalValue.toNumber());
};
export const valueIsBoolean = (value: unknown) => {
  return typeof value === 'boolean' || value === 'true' || value === 'false';
};
