import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';
import { saveAs } from "file-saver";

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
import type {
  Summary,
  SummaryEntry,
  Purchase,
  EstimateLoss,
  PurchaseGroupByPaymentMethod,
  PaymentMethodTx,
  RivalPurchasePrice
} from 'models/purchase.report.v2';
import { plus } from 'utils/format-number';
import moment from 'moment';
function* listSummaries({
  payload,
}: ReturnType<typeof actions.listSummariesRequest>) {
  const { keyName, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/dashboard/summary-purchase`, {
      params,
    })) as AxiosResponse<{ sale: Summary[] }>;
    const { timelines, data: purchases } = data.sale.reduce(
      (values, item) => {
        const key = `${item.tier.name}-${item.product.name}`;
        const result = item[keyName || 'weight'];
        const getDataValue = values.data[key] ?? {
          ...item,
          count: 0,
          price: {
            amount: '0',
            currency: item.price.currency,
          },
          amount: '0',
          weight: 0,
          summary: {
            [item.timeline.value]: {
              value: 0,
              label: keyName === 'amount' ? 'LAK' : 'KG',
            },
          },
          result: {
            price: 0,
            amount: 0,
            weight: 0,
            count: 0,
            tierPurchasePrice: 0,
          },
        };
        const getTimeLineValue = getDataValue.summary[item.timeline.value] ?? {
          value: 0,
          label: keyName === 'amount' ? 'LAK' : 'KG',
        };
        return {
          data: {
            ...values.data,
            [key]: {
              ...getDataValue,
              summary: {
                ...getDataValue.summary,
                [item.timeline.value]: {
                  value: plus(getTimeLineValue.value, result),
                  label: getTimeLineValue.label,
                },
              },
              result: {
                price: plus(getDataValue.result.price, item.price.amount),
                amount: plus(getDataValue.result.amount, item.amount),
                weight: plus(getDataValue.result.weight, item.weight),
                count: plus(getDataValue.result.count, item.count),
                tierPurchasePrice: plus(
                  getDataValue.result.tierPurchasePrice,
                  item.tierPurchasePrice.amount
                ),
              },
            },
          },
          timelines: {
            ...values.timelines,
            [item.timeline.value]: item.timeline.value,
          },
        };
      },
      { timelines: {}, data: {} } as {
        timelines: {
          [key: number]: number;
        };
        data: {
          [key: string]: SummaryEntry;
        };
      }
    );
    yield put(
      actions.listSummariesSuccess({
        entries: Object.values(purchases),
        timelines: Object.values(timelines).sort((a, b) => a - b),
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listSummariesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* listTransactions({
  payload,
}: ReturnType<typeof actions.listTransactionsRequest>) {
  const { result, isFirstRender, page, ...props } = payload;
  const resultObject = JSON.parse(result || '{}');

  if (typeof resultObject !== 'object') {
    yield put(actions.listTransactionsFailure());
    return;
  }
  if (props?.tier && props?.tier !== resultObject.tier) {
    yield put(actions.listTransactionsFailure());
    yield put(
      addAlert({
        message: 'ຂໍ້ມູນບໍ່ຖືກຕ້ອງ',
        serviceType: 'snackbar',
      })
    );
    return;
  }
  const params = {
    ...props,
    tier: resultObject.tier,
    product: resultObject.product,
  };
  try {
    const { data } = (yield call(API.get, `/v1/purchase/report/items`, {
      params,
    })) as AxiosResponse<{ purchase: Purchase[]; nextPageToken: string }>;
    yield put(
      actions.listTransactionsSuccess({
        entries: data.purchase,
        result: {
          amount: 0,
          count: 0,
          price: 0,
          weight: 0,
          tierPurchasePrice: 0,
          ...resultObject,
        },
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listTransactionsFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listMoisture({
  payload,
}: ReturnType<typeof actions.listMoistureRequest>) {
  const { result, isFirstRender, page, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/purchase/report/items`, {
      params,
    })) as AxiosResponse<{ purchase: Purchase[]; nextPageToken: string }>;
    yield put(
      actions.listMoistureSuccess({
        entries: data.purchase,
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listMoistureFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* estimateLoss({
  payload,
}: ReturnType<typeof actions.estimateLossRequest>) {
  const { result, page, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/purchase/report/estimate-loss`, {
      params,
    })) as AxiosResponse<EstimateLoss[]>;
    yield put(
      actions.estimateLossSuccess({
        entries: data,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.estimateLossFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listRivalPurchasePrice({
  payload,
}: ReturnType<typeof actions.rivalPurchasePriceRequest>) {
  const {  page, ...props } = payload;
 
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(
      API.get,
      `/v1/tiers/report/rival-purchase-prices`,
      {
        params,
      }
    )) as AxiosResponse<{
      rivalPurchasePrices: RivalPurchasePrice[];
      nextPageToken: string;
    }>;
    yield put(
      actions.rivalPurchasePriceSuccess({
        entries: data.rivalPurchasePrices,
        pageToken: data.nextPageToken,
        page,
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.rivalPurchasePriceFailure());
    if (errorData && errorData.serviceType === "snackbar") {
      yield put(addAlert(errorData));
    }
  }
}
function* watchListSummaries() {
  yield takeLatest(actions.Types.listSummariesRequest, listSummaries);
}
function* watchListTransactions() {
  yield takeLatest(actions.Types.listTransactionsRequest, listTransactions);
}
function* watchListMoisture() {
  yield takeLatest(actions.Types.listMoistureRequest, listMoisture);
}
function* watchEstimateLoss() {
  yield takeLatest(actions.Types.estimateLossRequest, estimateLoss);
}
function* watchPayment() {
  yield takeLatest(
    actions.Types.purchasePaymentMethodRequest,
    function* ({
      payload,
    }: ReturnType<typeof actions.purchasePaymentMethodRequest>) {
      const { ...props } = payload;
      const params = {
        ...props,
      };
      try {
        const { data } = (yield call(
          API.get,
          `/v1/purchase/report/group-by-customer-payment-method`,
          {
            params,
          }
        )) as AxiosResponse<{
          purchases: PurchaseGroupByPaymentMethod[];
        }>;
        yield put(
          actions.purchasePaymentMethodSuccess({
            entries: data.purchases,
          })
        );
      } catch (error) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.purchasePaymentMethodFailure());
        if (errorData && errorData.serviceType === 'snackbar') {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* watchPaymentTxs() {
  yield takeLatest(
    actions.Types.purchasePaymentMethodTxRequest,
    function* ({
      payload,
    }: ReturnType<typeof actions.purchasePaymentMethodTxRequest>) {
      const { ...props } = payload;
      const params = {
        ...props,
      };
      try {
        const { data } = (yield call(
          API.get,
          `/v1/purchase/report/items-for-customer-types`,
          {
            params,
          }
        )) as AxiosResponse<{
          purchases: PaymentMethodTx[];
          nextPageToken: string;
        }>;
        yield put(
          actions.purchasePaymentMethodTxSuccess({
            entries: data.purchases,
            pageToken: data.nextPageToken,
            page: 0,
          })
        );
      } catch (error) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.purchasePaymentMethodFailure());
        if (errorData && errorData.serviceType === 'snackbar') {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* watchListRivalPurchasePrice() {
  yield takeLatest(
    actions.Types.rivalPurchasePriceRequest,
    listRivalPurchasePrice
  );
}
function* watchExportMoisture() {
  yield takeLatest(
    actions.Types.exportMoistureRequest,
    function* ({
      payload,
    }: ReturnType<typeof actions.exportPurchaseMoistureRequest>) {
      const { ...props } = payload;
      const params = {
        ...props,
      };
      try {
        const { data } = (yield call(
          API.get,
          `/v1/purchase-by-moisture/export-csv`,
          {
            params,
          }
        )) as AxiosResponse;
        const blob = new Blob([data], {
          type: "text/plain;charset=utf-8",
        });
        yield saveAs(blob, `${moment().format("DD/MM/YYYY")}.csv`);
        yield put(actions.exportPurchaseMoistureSuccess());
      } catch (error) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.exportPurchaseMoistureFailure());
        if (errorData && errorData.serviceType === "snackbar") {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* saga() {
  yield all([
    fork(watchListSummaries),
    fork(watchListTransactions),
    fork(watchListMoisture),
    fork(watchEstimateLoss),
    fork(watchPayment),
    fork(watchPaymentTxs),
    fork(watchListRivalPurchasePrice),
    fork(watchExportMoisture),
  ]);
}
export default saga;
