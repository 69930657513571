import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
import type {
  Expense,
  TierCost,
  TierIncome,
  Summary,
  ProfitLossCustomer,
  CustomerTx
} from 'models/profit-loss';
import { plus } from 'utils/format-number';
function* listProfitLoss({
  payload,
}: ReturnType<typeof actions.listProfitLossRequest>) {
  const params = {
    ...payload,
  };
  try {
    const { data: tierIncome } = (yield call(
      API.get,
      `/v1/report/tier-income`,
      {
        params,
      }
    )) as AxiosResponse<TierIncome[]>;
    const { data: cost } = (yield call(
      API.get,
      `/v1/report/tier-cost-of-goods`,
      {
        params,
      }
    )) as AxiosResponse<TierCost[]>;
    const { data: expenses } = (yield call(
      API.get,
      `/v1/report/tier-expenses`,
      {
        params,
      }
    )) as AxiosResponse<Expense[]>;
    const summaryIncome = tierIncome.reduce(
      (entry, item) => {
        return {
          amount: plus(entry.amount, item.amount.amount),
          price: plus(entry.price, item.price.amount),
          volumnKg: plus(entry.volumnKg, item.volumnKg),
        };
      },
      { amount: 0, price: 0, volumnKg: 0 } as Summary
    );
    const summaryCost = cost.reduce(
      (entry, item) => {
        return {
          amount: plus(entry.amount, item.amount.amount),
          price: plus(entry.price, item.price.amount),
          volumnKg: plus(entry.volumnKg, item.volumnKg),
        };
      },
      { amount: 0, price: 0, volumnKg: 0 } as Summary
    );
    const summaryExpense = expenses.reduce(
      (entry, item) => {
        return {
          amount: plus(entry.amount, item.amount.amount),
          price: 0,
          volumnKg: 0,
        };
      },
      { amount: 0, price: 0, volumnKg: 0 } as Summary
    );
    yield put(
      actions.listProfitLossSuccess({
        cost,
        expense: expenses,
        income: tierIncome,
        isLoading: false,
        summary: {
          income: summaryIncome,
          cost: summaryCost,
          expense: summaryExpense,
        },
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listProfitLossFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchListProfitLoss() {
  yield takeLatest(actions.Types.listProfitLossRequest, listProfitLoss);
}
function* watchCustomers() {
  yield takeLatest(
    actions.Types.profitLossCustomerRequest,
    function* ({
      payload,
    }: ReturnType<typeof actions.profitLossCustomerRequest>) {
      const { ...props } = payload;
      const params = {
        ...props,
      };
      try {
        const { data } = (yield call(
          API.get,
          `/v1/sale/report/profit-loss-group-by-tier-product-customer`,
          {
            params,
          }
        )) as AxiosResponse<{
          sale: ProfitLossCustomer[];
          nextPageToken: string;
        }>;
        yield put(
          actions.profitLossCustomerSuccess({
            entities: data.sale,
            // pageToken: data.nextPageToken,
            // page: 0,
          })
        );
      } catch (error) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.profitLossCustomerFailure());
        if (errorData && errorData.serviceType === "snackbar") {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* watchCustomerTxs() {
  yield takeLatest(
    actions.Types.profitLossCustomerTxRequest,
    function* ({
      payload,
    }: ReturnType<typeof actions.profitLossCustomerTxRequest>) {
      const { ...props } = payload;
      const params = {
        ...props,
      };
      try {
        const { data } = (yield call(
          API.get,
          `/v1/sale/report/profit-loss-items`,
          {
            params,
          }
        )) as AxiosResponse<{
          sale: CustomerTx[];
          nextPageToken: string;
        }>;
        yield put(
          actions.profitLossCustomerTxSuccess({
            entities: data.sale,
            // pageToken: data.nextPageToken,
            // page: 0,
          })
        );
      } catch (error) {
        const errorData = findErrorToData({ error: error });
        yield put(actions.profitLossCustomerTxFailure());
        if (errorData && errorData.serviceType === "snackbar") {
          yield put(addAlert(errorData));
        }
      }
    }
  );
}
function* saga() {
  yield all([fork(watchListProfitLoss), fork(watchCustomers), fork(watchCustomerTxs)]);
}
export default saga;
